import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiArrowDownDropCircleOutline,
  mdiArrowRightDropCircleOutline,
  mdiFileSearchOutline,
  mdiPencil,
  mdiDeleteOutline,
} from "@mdi/js";
import {
  Grid,
  PaginationProps,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Table,
  TableFooter,
} from "semantic-ui-react";

import "./SimulationTable.scss";

interface SimulationTableProps {
  data: DrawingSimulationData[];
  onEdit: (data: DrawingSimulationData, isView?: boolean) => void;
  onDelete: (data: DrawingSimulationData) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  totalSimulations: number;
  isGetProductSimulationsLoading: boolean;
}

const LoadingComponent = () => (
  <Placeholder fluid>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
  </Placeholder>
);

const SimulationTable: React.FC<SimulationTableProps> = ({
  data,
  onEdit,
  onDelete,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  totalSimulations,
  isGetProductSimulationsLoading,
}) => {
  const [totalPages, setTotalPages] = useState(10);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalSimulations / pageSize));
  }, [totalSimulations, pageSize]);

  const handlePaginationChange = (
    e: any,
    { activePage, totalPages }: PaginationProps
  ) => {
    if (typeof activePage === "number") setPageNumber(activePage);
    if (typeof totalPages === "number") setTotalPages(totalPages);
  };

  const getCurrentImpact = (
    originalImpact: number,
    simulatedImpact: number
  ) => {
    return Math.fround(
      ((simulatedImpact - originalImpact) / originalImpact) * 100
    ).toFixed(4);
  };

  const handleRowClick = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);
    setExpandedRows(newExpandedRows);
  };

  const renderItemCaret = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return mdiArrowDownDropCircleOutline;
    } else {
      return mdiArrowRightDropCircleOutline;
    }
  };

  const renderItem = (item: DrawingSimulationData, index: number) => {
    const itemRows = [
      <Table.Row key={`row-data-${index}`}>
        <Table.Cell
          onClick={() => {
            handleRowClick(index);
          }}
        >
          <Icon
            className="table-icon"
            color="var(--mainColor)"
            path={renderItemCaret(index)}
            size={1.2}
          />
        </Table.Cell>
        <Table.Cell>{item.drawingName}</Table.Cell>
        <Table.Cell>{item.simulationName}</Table.Cell>
        <Table.Cell>{item.originalImpact}</Table.Cell>
        <Table.Cell>{item.simulatedImpact}</Table.Cell>
        <Table.Cell>
          {/* this should be uncomment after implementation done */}
          {/* {getCurrentImpact(item.originalImpact, item.simulatedImpact)} */}
        </Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onEdit(item, true);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiFileSearchOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onEdit(item);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiPencil}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onDelete(item);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--mainColor)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      </Table.Row>,
    ];

    if (expandedRows.includes(index)) {
      itemRows.push(
        <Table.Row key={"row-expanded-" + index}>
          <Table.Cell></Table.Cell>
          <Table.Cell>{item.drawingName}</Table.Cell>
          <Table.Cell>{item.simulationName}</Table.Cell>
          <Table.Cell>{item.simulatedImpact}</Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      );
    }

    return itemRows;
  };

  return (
    <Table singleLine className="custom-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Simulation Name</Table.HeaderCell>
          <Table.HeaderCell>
            Original Impact (kgCO<sub>2</sub> eq)
          </Table.HeaderCell>
          <Table.HeaderCell>
            Simulated Impact (kgCO<sub>2</sub> eq)
          </Table.HeaderCell>
          <Table.HeaderCell>Impact Reduction %</Table.HeaderCell>
          <Table.HeaderCell>Cost Impact %</Table.HeaderCell>
          <Table.HeaderCell>Simulation Result</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isGetProductSimulationsLoading && (
          <Table.Row>
            <Table.Cell colSpan="7">
              <LoadingComponent />
            </Table.Cell>
          </Table.Row>
        )}
        {!isGetProductSimulationsLoading &&
          data.map((item: DrawingSimulationData, index) =>
            renderItem(item, index)
          )}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="7">
            {/* TODO:- This should be implement after correct implementation done */}
            {/* <TableFooter
              activePage={pageNumber}
              totalPages={totalPages}
              handlePaginationChange={handlePaginationChange}
              pageSize={pageSize}
              changePageSize={setPageSize}
            /> */}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default SimulationTable;
