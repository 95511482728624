import { Grid } from "semantic-ui-react";
import {
  AccordionTitleView,
  DropDown,
  InputText,
} from "../../../../components";
import "./CultivarForm.scss";
import {
  FIELD_TYPES,
  FARMING_TYPES,
  CULTIVATION_TYPES,
} from "../../../../config/constants";

const CultivarField = ({
  cultivationType,
  setCultivationType,
  produceType,
  produceTypes,
  setProduceType,
  isProduceTypesLoading,
  country,
  countries,
  setCountry,
  setValue,
  isCountriesDataLoading,
  register,
  farmingType,
  setFarmingType,
  fieldType,
  setFieldType,
  formData,
}: any) => {
  return (
    <div>
      <AccordionTitleView
        accordionEnabled={false}
        title={"Cultivar"}
        plusIcon={false}
      />
      <div>
        <Grid>
          <Grid.Row divided>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <label className="textLabel">Data Type</label>
                    <br />
                    <label className="textLabel">{formData?.dataType}</label>
                  </Grid.Column>
                  <Grid.Column style={{ marginBottom: 15 }}>
                    <label className="textLabel">Produce Type</label>
                    <br />
                    <label className="textLabel">
                      {
                        produceTypes?.find(
                          (p: any) =>
                            parseInt(p.value) === parseInt(formData.produceType)
                        )?.text
                      }
                    </label>
                  </Grid.Column>
                  <Grid.Column style={{ marginBottom: 15 }}>
                    <label className="textLabel">Origin Country</label>
                    <br />
                    <label className="textLabel">
                      {
                        countries?.find(
                          (p: any) =>
                            parseInt(p.value) ===
                            parseInt(formData?.originCountry)
                        )?.text
                      }
                    </label>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="textLabel">Produce Cultivar</label>
                    <br />
                    <label className="textLabel">
                      {formData?.produceCultivar}
                    </label>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="textLabel">Farming Type</label>
                    <br />
                    <label className="textLabel">{formData?.farmingType}</label>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="textLabel">Field Type</label>
                    <br />
                    <label className="textLabel">
                      {formData?.farmingField}
                    </label>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <DropDown
                      labelName="Data Type*"
                      placeholder="Select Type"
                      defaultValue={cultivationType}
                      currentData={CULTIVATION_TYPES}
                      handleChangeState={(e: any, { value }: any) => {
                        setCultivationType(value);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DropDown
                      labelName="Produce Type*"
                      placeholder="Select Produce Type"
                      defaultValue={produceType}
                      currentData={produceTypes}
                      handleChangeState={(e: any, { value }: any) => {
                        setProduceType(value);
                      }}
                      loading={isProduceTypesLoading}
                      search
                      key={"produceType"}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DropDown
                      labelName="Origin Country"
                      placeholder="Select origin country"
                      defaultValue={country}
                      currentData={countries}
                      required
                      errorMessage={"Origin country required"}
                      handleChangeState={(e: any, { value }: any) => {
                        setCountry(value);
                        setValue("originCountry", value);
                      }}
                      loading={isCountriesDataLoading}
                      search
                      key={"country"}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <InputText
                      labelName={"Produce Cultivar"}
                      name="produceCultivar"
                      id="produceCultivar"
                      register={register}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DropDown
                      labelName="Farming Type"
                      placeholder="Select Farming Type"
                      defaultValue={farmingType}
                      currentData={FARMING_TYPES}
                      handleChangeState={(e: any, { value }: any) => {
                        setFarmingType(value);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DropDown
                      labelName="Field Type"
                      placeholder="Select Field Type"
                      defaultValue={fieldType}
                      currentData={FIELD_TYPES}
                      handleChangeState={(e: any, { value }: any) => {
                        setFieldType(value);
                      }}
                    />
                  </Grid.Column>

                  <InputText
                    type="hidden"
                    name="ingredientId"
                    id="ingredientId"
                    register={register}
                  />
                  <InputText
                    type="hidden"
                    name="supplierId"
                    id="supplierId"
                    register={register}
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default CultivarField;
