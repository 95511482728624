import React, { useState, useEffect } from "react";
import { Grid, Table, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";

import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonTable,
  NewImageUploader,
  Switch,
  CommonModal,
  TitleView,
  DropDown,
} from "../../../components";
import defaultUserImage from "../../../assets/images/default_icon.jpeg";
import { ProductUnitTable, UNIT_TYPES } from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";
import { isEmpty } from "lodash";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";

import "./productRegister.scss";
import {
  useCreateProduct,
  useUploadProductImage,
  usePostUSalesUnit,
} from "../../../api/product";

const ProductRegisterModal = ({ visibleModal, setVisibleModal }: any) => {
  const [addProductUnit, setProductUnit] = useState<any>([]);
  const [productType, setProductType] = useState<any>("PROCESS");
  const [defaultType, setDefaultType] = useState<any>(UNIT_TYPES[0].value);
  const [addDataObject, setAddDataObject] = useState({
    sales_unit_size: 0,
    sales_unit_type: "",
    ean_barcode: "",
    sales_unit_name: "",
  });
  const [imageData, setImageData] = useState<any>(null);
  const { height, width } = useWindowDimensions();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const {
    register: registerUnitSize,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitUnitSize,
    reset:resetUnitSize,
  } = useForm();

    //custom hook create product
    const { isLoading, mutate, data,reset:resetData } = useCreateProduct();

    //custom hook upload product Image
    const { isLoading: isLoadingProductImage, mutate: uploadProductImage } =
      useUploadProductImage();
  
    //custom hook upload product unit size
    const { isLoading: isLoadingSalesUnit, mutate: uploadSalesUnit } =
      usePostUSalesUnit();
  

  useEffect(() => {
    return () => {
      setProductUnit([]);
      setDefaultType(UNIT_TYPES[0].value);
      reset();
      resetUnitSizes()
    };
  }, [visibleModal]);

  //Handle close modal status
  const closeModalStatus = () => {
      let value = getValues();
      setVisibleModal(true, value);
  };

  //save product image process
  const saveProductImage = (data:any) => {    
    if (imageData) {
      let productImageData = {
        product_id: data?._id,
        file: imageData,
      };
      uploadProductImage(productImageData, {
        onSuccess: () => {
          finishProductImageSave();
        },
      });
    } else {
      successMessage("Product create successfully");
      closeModalStatus();
    }
  };

  //save product unit data
  const saveUnitData = (productData: any) => {
      if (addProductUnit.length > 0) {
        let productUnitData = {
          product_id: productData._id,
          sales_units: addProductUnit,
        };
        uploadSalesUnit(productUnitData, {
          onSuccess: () => {
            saveProductImage(productData);
          },
        });
      }
  };

  //finish and handle modal state
  const finishProductImageSave = () => {
    successMessage("Product create successfully");
    closeModalStatus();
  };

  // product unit size handler
  const removeDataHandler = (index: number) => {
    const newData = addProductUnit;
    newData.splice(index, 1);
    setProductUnit([...newData]);
  };

  //check product error view
  const checkProductSaveCondition = (data: any) => {
    if (isEmpty(data.product_name.trim())) {
      errorView("Product name is required");
      return true;
    } else if (addProductUnit.length <= 0) {
      errorView("Please add product sizes");
      return true;
    } else {
      return false;
    }
  };

  //Reset unit sizes
  const resetUnitSizes = () => {
    setDefaultType(UNIT_TYPES[0].value);
    reset({
      sales_unit_size: 0,
      sales_unit_type: "",
      ean_barcode: "",
      sales_unit_name: "",
    });
    resetUnitSize();
  };

  //add product unit data
  const addUnitData = (data: any) => {
      setAddDataObject({
        ...addDataObject,
        sales_unit_size: data?.sales_unit_size ?? "",
        sales_unit_type: defaultType ?? "",
        ean_barcode: data?.ean_barcode ?? "",
        sales_unit_name: data?.sales_unit_name ?? "",
      });
      setProductUnit([
        ...addProductUnit,
        {
          ...addDataObject,
          sales_unit_size: data?.sales_unit_size ?? 0,
          sales_unit_type: defaultType ?? "",
          ean_barcode: data?.ean_barcode ?? "",
          sales_unit_name: data?.sales_unit_name ?? "",
        },
      ]);
      resetUnitSizes()
  };

  //save product data
  const onSubmit = async (data: any) => {
    if (!checkProductSaveCondition(data)) {
      let { product_name, product_other_info, registration_number } = data;
      let productData = {
        product_name,
        product_other_info,
        registration_number,
        product_type: productType,
        sales_units: [],
        product_ingredients: [],
      };

      mutate(productData, {
        onSuccess: (data, variables, context) => {
          setValue("productData",{...data,...{product_id:data._id}})
          saveUnitData(data);
        },
      });
    }
  };

  //load product unit data table
  const loadTableData = () => {
    return addProductUnit.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.sales_unit_name ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.sales_unit_size ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.sales_unit_type ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.ean_barcode ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  onClick={() => removeDataHandler(index)}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //loading view
  if (isLoading || isLoadingSalesUnit || isLoadingProductImage) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          closeModalStatus();
          reset();
        }}
        centered={true}
        
        title="Add New Product"
      >
        <TitleView
          title="Product Info"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.product_name}
                            labelName={"Name"}
                            placeholder="Name"
                            name="product_name"
                            required={true}
                            lableName={"Product Name"}
                            errorMessage={"Please enter product name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.registration_number}
                            labelName={"Register number"}
                            placeholder="Register number"
                            name="registration_number"
                            required={false}
                          />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <NewImageUploader
                            defaultUserImage={defaultUserImage}
                            buttonTitle={"Upload an Image"}
                            customImageViewMain={"customImageView"}
                            imageData={(data: any) => {
                              setImageData(data);
                            }}
                          />
                        </Grid.Column>

                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <Switch
                            label={"Product Type"}
                            onClick={(data: any) => {
                              setProductType(data);
                            }}
                            titleOne={"Processed Product"}
                            titleTwo={"Farm Product"}
                            defaultValue={"PROCESS"}
                            titleOneValue={"PROCESS"}
                            titleTwoValue={"FARM"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputTextArea
                            register={register}
                            errors={errors.product_other_info}
                            labelName={"Additional Information"}
                            placeholder="Additional Information"
                            name="product_other_info"
                            customInputValidation={true}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                  <TitleView title="Add Unit sizes" />
                  <Grid>
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      mobile={16}
                    >
                      <form>
                        <Grid>
                          <Grid.Column computer={3} tablet={8} mobile={16}>
                            <InputText
                              register={registerUnitSize}
                              errors={errorsUnit.sales_unit_name}
                              labelName={"Unit Name"}
                              placeholder="Unit Name"
                              name="sales_unit_name"
                              required={true}
                              errorMessage="Unit name is required"
                            />
                          </Grid.Column>
                          <Grid.Column computer={3} tablet={8} mobile={16}>
                            <InputText
                              register={registerUnitSize}
                              errors={errorsUnit.sales_unit_size}
                              labelName={"Unit Size"}
                              placeholder="Unit Size"
                              name="sales_unit_size"
                              required={true}
                              type="number"
                              valueAsNumber={true}
                              errorMessage="Unit size is required"
                            />
                          </Grid.Column>
                          <Grid.Column computer={3} tablet={8} mobile={16}>
                            <DropDown
                              labelName={"Unit Type"}
                              placeholder="Type"
                              currentData={UNIT_TYPES}
                              defaultValue={defaultType}
                              customGridColumn={"customGridColomnType"}
                              handleChangeState={(e: any, { value }: any) => {
                                setDefaultType(value);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column computer={4} tablet={8} mobile={16}>
                            <InputText
                              register={registerUnitSize}
                              errors={errorsUnit.ean_barcode}
                              labelName={"EAN Barcode"}
                              placeholder="EAN Barcode"
                              name="ean_barcode"
                            />
                          </Grid.Column>

                          <Grid.Column computer={3} tablet={8} mobile={16}>
                            <CustomButton
                              theme="green"
                              customColumnStyle={"addUnitSize"}
                              icon="plus"
                              onClick={handleSubmitUnitSize((data)=>{                                
                                addUnitData(data)
                              })}
                            />
                          </Grid.Column>
                        </Grid>
                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="productRegisterTable"
                        >
                          {addProductUnit.length > 0 && (
                            <CommonTable tableHeaderData={ProductUnitTable}>
                              {loadTableData()}
                            </CommonTable>
                          )}
                        </Grid.Column>
                      </form>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        closeModalStatus();
                        reset();
                      }}
                    />
                  </div>
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save Product"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        const values = getValues();
                        setValue("enableNavigate", false);
                        onSubmit(values);
                      }}
                    />
                    <CustomButton
                      theme="green"
                      title={"Save and continue"}
                      customColumnStyle={"cardInsertSaveOther"}
                      onClick={() => {
                        const values = getValues();
                        setValue("enableNavigate", true);
                        onSubmit(values);
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default ProductRegisterModal;
