import { useContext, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, Table, Dimmer, Loader } from "semantic-ui-react";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useReactFlow } from "reactflow";
import { useQueryClient } from "@tanstack/react-query";
import { DrawingContext } from "../SimulateDrawingTool";
import useWindowDimensions from "../../../hooks/windowsSize";
import {
  InputText,
  InputTextArea,
  CustomButton,
  DropDown,
  TitleView,
  CommonTable,
} from "../../../components";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";

import {
  useGetClientWholeList,
  usePostGeneralClientData,
  usePutGeneralClientData,
  useGetGeneralClientDataById,
  usePostClientTransportMethodData,
  usePutClientTransportMethodData,
  useDeleteClientTransportMethodData,
  useDeleteClientData,
  useGetClientTransportMethods,
  usePostClientProductData,
} from "../../../api/general/client";
import { useGetTransportTypesList } from "../../../api/cultivations";
import { ClientTransportMethodsTable } from "../../../config/constants";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";

import { successMessage } from "../../../helpers/ErrorHandler";
import { isEmpty } from "lodash";
interface ProcessBaseProps {}

export const ClientBase = ({ modalData }: any) => {
  const { nodeItem, salesUnitId, productId } = modalData;
  const { deleteElements } = useReactFlow();

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);

  const reactFlow = useReactFlow();
  const queryClient = useQueryClient();

  const { height } = useWindowDimensions();

  const [clientName, setClientName] = useState<any>();
  const [isClientTextBox, setIsClientTextBox] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [clientId, setClientId] = useState(nodeItem?.data?.reference?.clientId);
  const [transportType, setTransportType] = useState("1");
  const [transportMethods, setTransportMethods] = useState([]);
  const [transportProducts, setTransportProducts] = useState([]);
  const [enableTransportMethodEditStatus, setEnableTransportMethodEditStatus] =
    useState<any>(false);
  const [enableTransportMethodEdit, setEnableTransportMethodEdit] =
    useState<any>();
  const [deleteData, setDeleteData] = useState<any>({});
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleDeleteClientModal, setVisibleDeleteClientModal] =
    useState(false);

  const { data: clientWholeList } = useGetClientWholeList();
  const { data: TransportTypeList } = useGetTransportTypesList();
  const {
    refetch: ClientTransportMethodsRefetch,
    isLoading: clientTransportMethodsLoading,
  } = useGetClientTransportMethods(
    nodeItem?.data?.reference?.clientId || clientId
  );

  const { mutate: postGeneralClientData } = usePostGeneralClientData();
  const { mutate: putGeneralClientData } = usePutGeneralClientData();
  const { refetch: ClientDataRefetch, isLoading: clientDataLoading } =
    useGetGeneralClientDataById(
      nodeItem?.data?.reference?.clientId || clientId
    );
  const { mutate: postClientTransportData } =
    usePostClientTransportMethodData();
  const { mutate: postClientProductData } = usePostClientProductData();
  const { mutate: putClientTransportData } = usePutClientTransportMethodData();
  const { mutate: deleteClientTransportData } =
    useDeleteClientTransportMethodData();
  const { mutate: deleteClientData } = useDeleteClientData();

  const getExistingClientData = async () => {
    const { data: clientData } = await ClientDataRefetch();
    const { data: clientTransportMethodsData, isSuccess } =
      await ClientTransportMethodsRefetch();
    if (isSuccess) {
      setTransportProducts(clientTransportMethodsData);
      const trData = clientTransportMethodsData?.find(
        (trMethod: any) =>
          trMethod?.productId === productId &&
          trMethod?.salesUnitId === salesUnitId
      );
      setTransportMethods(trData?.transportMethods);
    }

    setClientName({
      clientName: clientData?.clientName,
      clientId: clientData?._id,
    });
    setValue("clientName", {
      clientName: clientData?.clientName,
      clientId: clientData?._id,
    });
    setValue("clientEmail", clientData?.clientEmail);
    setValue("clientPhone", clientData?.clientPhone);
    setValue("clientAddress", clientData?.clientAddress);
    setValue("clientCity", clientData?.clientCity);
    setValue("clientProvince", clientData?.clientProvince);
    setValue("clientCountry", clientData?.clientCountry);
    setValue("clientZipCode", clientData?.clientZipCode);
    setValue("clientOtherInfo", clientData?.clientOtherInfo);
  };

  useEffect(() => {
    if (nodeItem?.data?.reference) {
      getExistingClientData();
    }

    return () => {
      setEnableTransportMethodEditStatus(false);
    };
  }, [nodeItem]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: registerTransportMethods,
    formState: { errors: errorsTransport },
    setValue: setTransportMethodValues,
    handleSubmit: handleSubmitTransportMethods,
    reset: resetTransportMethods,
  } = useForm();

  const clientSaveHandler = () => {
    const dataToSave = {
      clientName: getValues()?.["clientNameText"],
      clientEmail: getValues()?.["clientEmail"],
      clientPhone: getValues()?.["clientPhone"],
      clientAddress: getValues()?.["clientAddress"],
      clientCity: getValues()?.["clientCity"],
      clientProvince: getValues()?.["clientProvince"],
      clientCountry: getValues()?.["clientCountry"],
      clientZipCode: getValues()?.["clientZipCode"],
      clientOtherInfo: getValues()?.["clientOtherInfo"],
      product: [
        {
          productId: productId,
          salesUnitId: salesUnitId,
        },
      ],
      clientStatus: "ACTIVE",
    };
    postGeneralClientData(dataToSave, {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(["getClientWholeList"]);
        setClientName({
          clientName: data?.clientName,
          clientId: data?._id,
        });
        setValue("clientName", {
          clientName: data?.clientName,
          clientId: data?._id,
        });
        const updatedNode = {
          ...nodeItem,
          data: {
            ...nodeItem?.data,
            // reference is mongoose mix type so you can set any type of here please set necessary reference only
            reference: {
              clientId: data?._id,
            },
          },
        };
        const updatedChartNodes = chartNodes.map((n: any) => {
          if (n.id === nodeItem?.id) {
            return updatedNode;
          }
          return n;
        });
        setChartNodes(updatedChartNodes);
        setNodeItem(updatedNode);
        saveDrawing();
        successMessage("Client added successfully");
        setIsClientTextBox(false);
      },
    });
  };

  const onSubmit = (data: any) => {
    if (isEmpty(data?.["clientNameText"]) && isEmpty(data?.["clientName"])) {
      setIsValidate(isEmpty(data?.["clientName"]) ? true : false);
    } else if (isEmpty(data?.["clientNameText"])) {
      const trData: any = transportProducts?.find(
        (trMethod: any) =>
          trMethod?.productId === productId &&
          trMethod?.salesUnitId === salesUnitId
      );
      console.log("TRRR", trData);

      const dataToSave = {
        _id: data?.clientName?.["clientId"],
        clientName: data?.clientName?.["clientName"],
        clientEmail: data?.["clientEmail"],
        clientPhone: data?.["clientPhone"],
        clientAddress: data?.["clientAddress"],
        clientCity: data?.["clientCity"],
        clientProvince: data?.["clientProvince"],
        clientCountry: data?.["clientCountry"],
        clientZipCode: data?.["clientZipCode"],
        clientOtherInfo: data?.["clientOtherInfo"],
        clientStatus: "ACTIVE",
      };

      if (nodeItem?.data?.reference) {
        putGeneralClientData(dataToSave, {
          onSuccess: (data: any) => {
            const productData = {
              clientId: clientId,
              product: {
                productId: productId,
                salesUnitId: salesUnitId,
              },
            };

            queryClient.invalidateQueries(["getClientWholeList"]);
            setClientName({
              clientName: data?.clientName,
              clientId: data?._id,
            });
            setValue("clientName", {
              clientName: data?.clientName,
              clientId: data?._id,
            });
            if (!trData) {
              postClientProductData(productData, {
                onSuccess: (data: any) => {
                  setNodeItem(null);
                  successMessage("Client updated successfully");
                },
              });
            } else {
              setNodeItem(null);
              successMessage("Client updated successfully");
            }
          },
        });
      } else {
        putGeneralClientData(dataToSave, {
          onSuccess: (data: any) => {
            const productData = {
              clientId: clientId,
              product: {
                productId: productId,
                salesUnitId: salesUnitId,
                transportMethods: [],
              },
            };
            queryClient.invalidateQueries(["getClientWholeList"]);
            setClientName({
              clientName: data?.clientName,
              clientId: data?._id,
            });
            setValue("clientName", {
              clientName: data?.clientName,
              clientId: data?._id,
            });
            const updatedNode = {
              ...nodeItem,
              data: {
                ...nodeItem?.data,
                // reference is mongoose mix type so you can set any type of here please set necessary reference only
                reference: {
                  clientId: data?._id,
                },
              },
            };
            const updatedChartNodes = chartNodes.map((n: any) => {
              if (n.id === nodeItem?.id) {
                return updatedNode;
              }
              return n;
            });
            if (!trData) {
              postClientProductData(productData, {
                onSuccess: (data: any) => {
                  setChartNodes(updatedChartNodes);
                  setNodeItem(updatedNode);
                  saveDrawing();
                  successMessage("Client updated successfully");
                },
              });
            } else {
              setChartNodes(updatedChartNodes);
              setNodeItem(updatedNode);
              saveDrawing();
              successMessage("Client updated successfully");
            }
          },
        });
      }
    } else {
      clientSaveHandler();
    }
  };

  const addTransportData = (data: any) => {
    const dataToAdd = {
      clientId: clientId,
      salesUnitId: salesUnitId,
      productId: productId,
      transportMethodData: {
        transportType: Number(transportType),
        from: data?.from,
        to: data?.to,
        distance: data?.distance || 0,
        weight: data?.weight || 0,
      },
    };

    postClientTransportData(dataToAdd, {
      onSuccess: async (data: any) => {
        const { data: clientTransportMethodsData } =
          await ClientTransportMethodsRefetch();
        setTransportProducts(clientTransportMethodsData);
        const trData = clientTransportMethodsData?.find(
          (trMethod: any) =>
            trMethod?.productId === productId &&
            trMethod?.salesUnitId === salesUnitId
        );
        setTransportMethods(trData?.transportMethods);
        setTransportType("1");
        resetTransportMethods();
      },
    });
  };

  const editTransportData = (data: any) => {
    const dataToEdit = {
      clientId: clientId,
      transportMethodId: enableTransportMethodEdit?._id,
      transportMethodData: {
        transportType: Number(transportType),
        from: data?.from,
        to: data?.to,
        distance: data?.distance || 0,
        weight: data?.weight || 0,
      },
    };

    putClientTransportData(dataToEdit, {
      onSuccess: async (data: any) => {
        const { data: clientTransportMethodsData } =
          await ClientTransportMethodsRefetch();
        setTransportProducts(clientTransportMethodsData);
        const trData = clientTransportMethodsData?.find(
          (trMethod: any) =>
            trMethod?.productId === productId &&
            trMethod?.salesUnitId === salesUnitId
        );
        setTransportMethods(trData?.transportMethods);
        setTransportType("1");
        resetTransportMethods();
        setEnableTransportMethodEditStatus(false);
      },
    });
  };

  const deleteTransportData = (data: any) => {
    const dataToDelete = {
      clientId: clientId,
      productId: productId,
      salesUnitId: salesUnitId,
      transportMethodId: data?._id,
    };

    deleteClientTransportData(dataToDelete, {
      onSuccess: async (data: any) => {
        const { data: clientTransportMethodsData } =
          await ClientTransportMethodsRefetch();
        setTransportProducts(clientTransportMethodsData);
        const trData = clientTransportMethodsData?.find(
          (trMethod: any) =>
            trMethod?.productId === productId &&
            trMethod?.salesUnitId === salesUnitId
        );
        setTransportMethods(trData?.transportMethods);
        setTransportType("1");
        resetTransportMethods();
        setEnableTransportMethodEditStatus(false);
      },
    });
  };

  const clientTextBoxCloseHandler = () => {
    setIsClientTextBox(false);
    setValue("clientNameText", "");
    setClientName("");
    setValue("clientName", "");
  };

  const handleTransportMethodUpdate = (data: any) => {
    setEnableTransportMethodEdit(data);
    setEnableTransportMethodEditStatus(true);
    setTransportMethodValues("from", data?.from);
    setTransportMethodValues("to", data?.to);
    setTransportMethodValues("distance", data?.distance);
    setTransportMethodValues("weight", data?.weight);
  };

  //load product unit data table
  const loadTableData = () => {
    return transportMethods?.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>
              {TransportTypeList.find(
                (t: any) => t.value == data?.transportType
              )?.["text"] ?? ""}
            </p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.from ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.to ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.distance ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.weight ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    handleTransportMethodUpdate(data);
                    setTransportType(`${data?.transportType}`);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    setDeleteData(data);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  if (nodeItem?.data.reference && clientDataLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 80,
                overflowX: "hidden",
              }}
            >
              <Grid>
                {!isClientTextBox ? (
                  <Grid.Column computer={8} tablet={8} mobile={16}>
                    <DropDown
                      labelName={"Client Name*"}
                      placeholder="Select Client Name"
                      disabled={nodeItem?.data?.reference ? true : false}
                      required
                      validate={isValidate}
                      currentData={clientWholeList}
                      defaultValue={clientName}
                      customGridColumn={"customGridColomnTyp"}
                      handleChangeState={(e: any, { value }: any) => {
                        if (value === "new") {
                          setIsClientTextBox(true);
                        } else {
                          setClientId(value?.clientId);
                          setClientName(value);
                          setValue("clientName", value);

                          setTimeout(() => {
                            getExistingClientData();
                          }, 1000);
                        }
                      }}
                      errorMessage={"Please select a client"}
                    />
                  </Grid.Column>
                ) : (
                  <>
                    <Grid.Column computer={10} tablet={8} mobile={16}>
                      <InputText
                        register={register}
                        errors={errors.clientNameText}
                        labelName={"Client Name*"}
                        placeholder="Type your client name"
                        name="clientNameText"
                        required={true}
                        validateHandle={(e: any) => {
                          return !isEmpty(e);
                        }}
                        errorMessage={"Please enter client name"}
                      />
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                      <CustomButton
                        theme="green"
                        onClick={() => clientSaveHandler()}
                        customColumnStyle={"customIngredientUnitButton"}
                        title="Save"
                        type="button"
                      />
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={8} mobile={16}>
                      <CustomButton
                        theme="green"
                        onClick={clientTextBoxCloseHandler}
                        customColumnStyle={"customIngredientUnitButton"}
                        title="Back"
                        type="button"
                      />
                    </Grid.Column>
                  </>
                )}

                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client Email"}
                    placeholder="Client Email"
                    name={`clientEmail`}
                    type={"email"}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client Phone"}
                    placeholder="Client Phone"
                    name={`clientPhone`}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client Address"}
                    placeholder="Client Address"
                    name={`clientAddress`}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client City"}
                    placeholder="Client City"
                    name={`clientCity`}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client Province"}
                    placeholder="Client Province"
                    name={`clientProvince`}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client Country"}
                    placeholder="Client Country"
                    name={`clientCountry`}
                  />
                </Grid.Column>

                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    register={register}
                    labelName={"Client Zip Code"}
                    placeholder="Client Zip Code"
                    name={`clientZipCode`}
                  />
                </Grid.Column>

                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputTextArea
                    register={register}
                    labelName={"Client Other Info"}
                    placeholder="Client Other Info"
                    name={`clientOtherInfo`}
                  />
                </Grid.Column>
              </Grid>
              {clientName && (
                <>
                  <TitleView title="Add Transportation Methods" />
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <form>
                        <Grid>
                          <Grid.Column computer={5} tablet={8} mobile={16}>
                            <DropDown
                              labelName={"Transport Type"}
                              placeholder="Select"
                              currentData={TransportTypeList || []}
                              defaultValue={transportType}
                              customGridColumn={"customGridColomnType"}
                              handleChangeState={(e: any, { value }: any) => {
                                setTransportType(value);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column computer={5} tablet={8} mobile={16}>
                            <InputText
                              register={registerTransportMethods}
                              // errors={errorsUnit.sales_unit_name}
                              labelName={"From"}
                              placeholder="From"
                              name="from"
                              // required={true}
                              // errorMessage="From is required"
                            />
                          </Grid.Column>
                          <Grid.Column computer={5} tablet={8} mobile={16}>
                            <InputText
                              register={registerTransportMethods}
                              // errors={errorsUnit.sales_unit_size}
                              labelName={"To"}
                              placeholder="To"
                              name="to"
                              // required={true}
                              // errorMessage="To is required"
                            />
                          </Grid.Column>

                          <Grid.Column computer={5} tablet={8} mobile={16}>
                            <InputText
                              register={registerTransportMethods}
                              // errors={errorsUnit.ean_barcode}
                              labelName={"Distance (Km)"}
                              placeholder="Distance (Km)"
                              name="distance"
                              type="number"
                              valueAsNumber={true}
                              customInputValidation={true}
                              minNumber={0}
                              step={0.001}
                            />
                          </Grid.Column>
                          <Grid.Column computer={5} tablet={8} mobile={16}>
                            <InputText
                              register={registerTransportMethods}
                              // errors={errorsUnit.ean_barcode}
                              labelName={"Weight (Kg)"}
                              placeholder="Weight (Kg)"
                              name="weight"
                              type="number"
                              valueAsNumber={true}
                              customInputValidation={true}
                              minNumber={0}
                              step={0.001}
                            />
                          </Grid.Column>
                          <Grid.Column computer={5} tablet={8} mobile={16}>
                            {enableTransportMethodEditStatus ? (
                              <CustomButton
                                theme="green"
                                customColumnStyle={"addUnitSize"}
                                title="Edit"
                                onClick={handleSubmitTransportMethods(
                                  (data) => {
                                    editTransportData(data);
                                  }
                                )}
                              />
                            ) : (
                              <CustomButton
                                theme="green"
                                customColumnStyle={"addUnitSize"}
                                icon="plus"
                                onClick={handleSubmitTransportMethods(
                                  (data) => {
                                    addTransportData(data);
                                  }
                                )}
                              />
                            )}
                          </Grid.Column>
                        </Grid>
                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="productRegisterTable"
                        >
                          {transportMethods?.length > 0 && (
                            <CommonTable
                              tableHeaderData={ClientTransportMethodsTable}
                            >
                              {loadTableData()}
                            </CommonTable>
                          )}
                        </Grid.Column>
                      </form>
                    </Grid.Column>
                  </Grid>
                </>
              )}
            </div>
            <MainBottomButtonView
              cancelStatus={true}
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              deleteButton={() => setVisibleDeleteClientModal(true)}
              cancelButton={() => setNodeItem(null)}
              saveTitle={
                nodeItem?.data.reference || clientName ? "Update" : "Save"
              }
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          deleteTransportData(deleteData);
          setVisibleDeleteModal(false);
        }}
        title="Delete Transport Method"
        subTitle="Are you sure you want to delete transport method?"
      />
      <ConfirmModal
        viewModal={visibleDeleteClientModal}
        closeModal={() => setVisibleDeleteClientModal(false)}
        cancel={() => {
          setVisibleDeleteClientModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            clientId: clientId,
          };
          deleteClientData(dataToDelete, {
            onSuccess: (data: any) => {
              setNodeItem(null);
              onNodesDelete();
              successMessage("Client deleted successfully");
            },
          });

          setVisibleDeleteClientModal(false);
        }}
        title="Delete Product Client"
        subTitle="Are you sure you want to remove the client from product?"
      />
    </Grid>
  );
};
