import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";
import { successMessage } from "../../helpers/ErrorHandler";

export const useCreateSimulateDrawing = (opt?: UseMutationOptions) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/simulation-drawing-tool`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetSimulationDrawing = (
  salesUnitId: string,
  productId: string
) =>
  useQuery<any, ApiServiceErr>(
    [salesUnitId, productId, "getSimulationDrawing"],
    async () => {
      const response = await axiosApi.get("/simulation-drawing-tool", {
        params: { salesUnitId, productId },
      });
      return response.data;
    },
    {
      enabled: !!salesUnitId && !!productId,
    }
  );

export const useGetSimulationDrawingById = (
  simulationDrawingId: string | null
) =>
  useQuery<any, ApiServiceErr>(
    [simulationDrawingId, "useGetSimulationDrawingById"],
    async () => {
      const response = await axiosApi.get(
        `/simulation-drawing-tool/${simulationDrawingId}`
      );
      return response.data;
    },
    {
      enabled: !!simulationDrawingId,
    }
  );

export const useUpdateSimulateDrawing = (opt?: UseMutationOptions) =>
  useMutation<any, ApiServiceErr, any>(
    async ({ data, simulationDrawingId }) => {
      const response = await axiosApi.put(
        `/simulation-drawing-tool/${simulationDrawingId}`,
        data
      );
      successMessage(response.data);
      return response.data;
    },
    opt
  );
