import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  AccordionTitle,
  Accordion,
  AccordionContent,
  Table,
} from "semantic-ui-react";
import {
  CustomButton,
  CommonTable,
  InputText,
  TitleView,
  AccordionTitleView,
} from "../../../components";
import { DrawingContext } from "../../drawing_tool/DrawingTool";

import { usePostProcessGateRecipeData } from "../../../api/process";
import { useGetSuppliersByIds } from "../../../api/supplier";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import { useForm } from "react-hook-form";
import { findIncomingIngredientsAndProcess } from "../../../helpers/DrawingHelper";
import { useGetProcessRecipeByProcessId } from "../../../api/SupplierProcessGate";
import { isEmpty } from "lodash";

const RecipeContent = ({
  processId,
  recipeId,
  nodeItem,
  type,
  checkRecipeValidation = () => {},
}: any) => {
  const { chartEdges, chartNodes } = useContext(DrawingContext);
  const [supplierIds, setSupplierIds] = useState<string[]>([]);
  const [processTotal, setProcessTotal] = useState<any>(0);
  const [ingredientTotal, setIngredientTotal] = useState<any>(0);
  const [validationError, setValidationError] = useState<{
    id: string;
    error: string;
  }>({ id: "", error: "" });
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const [recipeData, setRecipeData] = useState<any>({
    ingredientList: [],
    processList: [],
    processId: "",
  });

  const { data: processRecipeData, refetch: refetchProcessRecipe } =
    useGetProcessRecipeByProcessId({
      recipeId: recipeId,
      type: type,
    });

  const values = useMemo(() => {
    return {
      recipeData: recipeData,
      nodeItem,
      processId,
      recipeId,
      type,
    };
  }, [accordionEnabled, recipeData]);

  const loadRecipeData = () => {
    let processList: any[] = [];
    let ingredientList: any[] = [];
    try {
      const incomings = findIncomingIngredientsAndProcess(
        chartEdges,
        chartNodes,
        nodeItem.id,
        ingredientList,
        processList
      );
      if (
        incomings.ingredientList.length > 0 ||
        incomings.processList.length > 0
      ) {
        let notSavedProcessCount = 0;
        let notSavedPreviousComponent = 0;
        processList = incomings?.processList
          ?.map((item: any) => {
            const processMap = processRecipeData?.processList?.find(
              (process: any) => item?.data?.reference?.processId === process.id
            );
            if (!item?.data?.reference?.processId) {
              notSavedProcessCount++;
            }
            if (item.previousComponentId === null) {
              notSavedPreviousComponent++;
            }
            if (processMap) {
              return {
                ...processMap,
                ...item,
              };
            }
            return item;
          })
          .filter(
            (item: any) => item?.data?.reference?.processId !== processId
          );
        let notSavedIngredientCount = 0;
        ingredientList = incomings?.ingredientList
          .map((item: any) => {
            const ingredientMap = processRecipeData?.ingredientList?.find(
              (ingredient: any) =>
                item?.data?.reference?.ingredientId === ingredient.id &&
                item?.data?.reference?.supplierId === ingredient.supplierId
            );
            let supplierName = "";
            if (!item?.data?.reference?.supplierId) {
              notSavedIngredientCount++;
            }
            if (item.previousComponentId === null) {
              notSavedPreviousComponent++;
            }
            if (ingredientMap) {
              return {
                supplierName,
                ...ingredientMap,
                ...item,
              };
            }
            return { ...item, supplierName };
          })
          .filter(
            (v: any, i: any, a: any) =>
              a.findIndex(
                (t: any) =>
                  t.data?.reference?.ingredientId ===
                    v.data?.reference?.ingredientId &&
                  t?.data?.reference?.supplierId ===
                    v?.data?.reference?.supplierId
              ) === i
          );

        if (notSavedIngredientCount > 0) {
          errorView("Please save ingredient for this product");
          return false;
        }
        if (notSavedProcessCount > 0) {
          errorView("Please save process for this product");
          return false;
        }
        if (notSavedPreviousComponent > 0) {
          errorView("Please save previous process for this recipe");
          return false;
        }
        setRecipeData({
          ingredientList,
          processList,
          processId,
        });
        return true;
      } else {
        errorView("Please create ingredient or process");
        return false;
      }
    } catch (error) {
      errorView("Something went wrong");
      return false;
    }
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    mutate: postProcessGateRecipeData,
    isSuccess: isSuccessPostProcessGateRecipeData,
  } = usePostProcessGateRecipeData();
  const suppliers = useGetSuppliersByIds(supplierIds);

  const onSubmitHandler = (data: any) => {
    const formData: any = getValues();
    let processList = [];
    let ingredientList = [];
    for (const key in formData) {
      if (key.includes("process") && key.split("-")[1]) {
        const processNode = values.recipeData?.processList?.find(
          (process: any) =>
            process.data?.reference?.processId === key.split("-")[1]
        );
        processList.push({
          id: key.split("-")[1],
          value: formData[key].value,
          parentNodeType: processNode?.parentNodeDetails?.type,
          parentNodeId:
            processNode?.parentNodeDetails?.processGateId ||
            processNode?.parentNodeDetails?.farmGateId,
          previousComponentId: processNode?.previousComponentId,
          previousComponentType: processNode?.previousComponentType,
        });
      } else if (key.includes("ingredient") && key.split("-")[2]) {
        const ingredientNode = values.recipeData?.ingredientList?.find(
          (ingredient: any) =>
            ingredient.data?.reference?.ingredientId === key.split("-")[2]
        );
        ingredientList.push({
          id: key.split("-")[2],
          value: formData[key].value,
          supplierId: key.split("-")[1],
          previousComponentId: ingredientNode?.previousComponentId,
          previousComponentType: ingredientNode?.previousComponentType,
        });
      }
    }
    const recipeData = {
      processId,
      processList,
      ingredientList,
      type,
    };
    postProcessGateRecipeData(recipeData);
  };

  useEffect(() => {
    loadRecipeData();
  }, [accordionEnabled]);

  useEffect(() => {
    if (isSuccessPostProcessGateRecipeData) {
      successMessage("Recipe Added Successfully");
      setAccordionEnabled(false);
    }
  }, [isSuccessPostProcessGateRecipeData]);

  useEffect(() => {
    if (values.recipeData?.ingredientList?.length > 0) {
      values.recipeData?.ingredientList?.map((ingredient: any) => {
        setSupplierIds((ids) => {
          if (ids) {
            return [...ids, ingredient?.data?.reference?.supplierId];
          }
          return [ingredient?.data?.reference?.supplierId];
        });
      });
    }
  }, [values]);

  const getSupplierName = (
    supplierData: any,
    ingredientId: string,
    supplierId: string
  ) => {
    let supplierName = "";
    if (supplierData) {
      supplierData.map((supplier: any) => {
        if (
          supplier.isFetched &&
          supplier.data._id === supplierId &&
          supplier.data.ingredientIds.includes(ingredientId)
        ) {
          supplierName = supplier.data.supplier_name;
        }
      });
    }
    return supplierName;
  };

  const handleClick = () => {
    if (!loadRecipeData() || isEmpty(processId)) {      
      checkRecipeValidation()
      return;
    }
    setAccordionEnabled(!accordionEnabled);
  };

  const validateTotalValue = (id: string, value: string) => {
    setValidationError({ id: "", error: "" });
    if (isNaN(parseFloat(value))) {
      setValidationError({
        id,
        error: "Please enter a valid number",
      });
      const formData: any = getValues();
      for (const key in formData) {
        if (key.includes("process")) {
          setProcessTotal(0);
        } else if (key.includes("ingredient")) {
          setIngredientTotal(0);
        }
      }
      return;
    } else {
      const formData: any = getValues();
      let totalProcessValue = 0;
      let totalIngValue = 0;

      for (const key in formData) {
        if (
          key.includes("process") &&
          key.split("-")[1] &&
          formData[key].value
        ) {
          totalProcessValue += parseFloat(formData[key].value);
          setProcessTotal(totalProcessValue);
        } else if (
          key.includes("ingredient") &&
          key.split("-")[2] &&
          formData[key].value
        ) {
          totalIngValue += parseFloat(formData[key].value);
          setIngredientTotal(totalIngValue);
        }
      }
    }
  };

  const renderProcessListTable = useCallback(() => {
    return values.recipeData.processList?.map((process: any) => {
      const processId = process.data?.reference?.processId;
      const defaultValue = process?.value;
      return (
        <Table.Row className="tbleR" key={processId}>
          <Table.Cell>
            <p>{process?.data?.label}</p>
          </Table.Cell>
          <Table.Cell>
            <InputText
              register={register}
              name={`process-${processId}.value`}
              control={control}
              defaultValues={defaultValue}
              onChangeFunction={(e: any) => {
                validateTotalValue(processId, e.target.value);
              }}
              valueAsNumber={true}
            />
            {validationError.id === processId && (
              <span style={{ color: "red" }}>{validationError.error}</span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  }, [values, accordionEnabled]);

  return (
    <div>
      <Accordion>
        <AccordionTitle active={accordionEnabled} onClick={handleClick}>
          <AccordionTitleView
            accordionEnabled={accordionEnabled}
            title={"Recipe"}
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div>
              <Grid>
                {values.recipeData?.processList?.length > 0 && (
                  <>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <TitleView
                          CustomTextTitle="customT"
                          CustomTitleViewMain="recipeTitle"
                          title="Process (s)"
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <CommonTable
                          tableHeaderData={[
                            { name: "Name", col: 12 },
                            { name: "Value %", col: 4 },
                          ]}
                        >
                          {renderProcessListTable()}
                        </CommonTable>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
              <Grid>
                {values.recipeData?.ingredientList?.length > 0 && (
                  <>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <TitleView
                          CustomTextTitle="customT"
                          CustomTitleViewMain="recipeTitle"
                          title="Ingredient (s)"
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <CommonTable
                          tableHeaderData={[
                            { name: "Name", col: 6 },
                            { name: "Supplier", col: 6 },
                            { name: "Value %", col: 4 },
                          ]}
                        >
                          {values.recipeData?.ingredientList?.map(
                            (ingredient: any, index: any) => {
                              return (
                                <Table.Row
                                  key={
                                    ingredient?.data?.reference?.ingredientId
                                  }
                                >
                                  <Table.Cell>
                                    <p>{ingredient?.data?.label}</p>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>
                                      {getSupplierName(
                                        suppliers,
                                        ingredient?.data?.reference
                                          .ingredientId,
                                        ingredient?.data?.reference?.supplierId
                                      )}
                                    </p>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <InputText
                                      register={register}
                                      name={`ingredient-${ingredient?.data?.reference?.supplierId}-${ingredient?.data?.reference?.ingredientId}.value`}
                                      control={control}
                                      defaultValues={ingredient?.value}
                                      onChangeFunction={(e: any) => {
                                        validateTotalValue(
                                          ingredient?.data?.reference
                                            ?.ingredientId,
                                          e.target.value
                                        );
                                      }}
                                      valueAsNumber={false}
                                    />
                                    {validationError.id ===
                                      ingredient?.data?.reference
                                        ?.ingredientId && (
                                      <span style={{ color: "red" }}>
                                        {validationError.error}
                                      </span>
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          )}
                        </CommonTable>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
            </div>

            <div className="sipAddTotalError">
              {processTotal > 100 ? (
                <span style={{ color: "red" }}>
                  Sum of recipe process(es) value(s) should be less than or
                  equal to 100%
                </span>
              ) : ingredientTotal > 100 ? (
                <span style={{ color: "red" }}>
                  Sum of recipe ingredient(s) value(s) should be less than or
                  equal to 100%
                </span>
              ) : (
                Number(processTotal) + Number(ingredientTotal) > 100 && (
                  <span style={{ color: "red" }}>
                    Sum of ingredient(s) value(s) and process(es) value(s)
                    should be less than or equal to 100%
                  </span>
                )
              )}
            </div>
            <div className="buttonGroup">
              <div className="sipCancel">
                <CustomButton
                  onClick={() => {
                    setAccordionEnabled(false);
                  }}
                  title="Cancel"
                  theme="green"
                  buttonOutLine={true}
                  disabled={Boolean(validationError.error)}
                />
              </div>
              <div className="sipAdd">
                <CustomButton
                  onClick={handleSubmit(onSubmitHandler)}
                  title="Save"
                  theme="green"
                  disabled={
                    processTotal > 100 ||
                    ingredientTotal > 100 ||
                    Number(processTotal) + Number(ingredientTotal) > 100
                  }
                />
              </div>
            </div>
          </form>
        </AccordionContent>
      </Accordion>
    </div>
  );
};

export default RecipeContent;
