import { MdAgriculture, MdBackpack } from "react-icons/md";
import { FaLeaf } from "react-icons/fa";
import { getCompanyLogo } from "../utils/utils";
import { ProOptions } from "reactflow";
import { images } from "../assets/images";

const API_URL = "https://api.naturepreserve.app/graphql";
// const API_URL = "http://localhost:3002/graphql";
const NP_API_URL = "https://api.naturepreserve.app";
// const NP_API_URL = "http://localhost:3002";

const NP_SOCKET_API_URL = "wss://api.naturepreserve.app";

const NP_CALCULATOR_API_URL = "https://r99sbn0ct4.execute-api.eu-north-1.amazonaws.com/stage";

// Alfa Laval for BLUE theme.
// Nature Preserve for GREEN theme.
const THEME = "GREEN";
const COMPANY_LOGO = getCompanyLogo(THEME);
// Alfa Laval for BLUE theme.
// Nature Preserve for GREEN theme.
const COMPANY_NAME = "Nature Preserve";
const proOptionsForReactFlow: ProOptions = {
  account: "bs@naturepreserve.co",
  hideAttribution: true,
};
const StepOne = [
  {
    id: 1,
    label: "Supplier Name",
    type: "text",
    name: "supplier_name",
    placeholder: "Supplier Name",
  },
  {
    id: 2,
    label: "Produce Type",
    type: "text",
    name: "produce_type",
    placeholder: "Produce type",
  },
  {
    id: 3,
    label: "Produce Cultivar",
    type: "text",
    name: "produce_cultivar",
    placeholder: "Produce Cultivar",
  },
  {
    id: 4,
    label: "Origin",
    type: "text",
    name: "origin",
    placeholder: "Origin",
  },
  {
    id: 5,
    label: "Organic",
    type: "text",
    name: "Organic",
    placeholder: "Organic",
  },
  {
    id: 5,
    label: "Production Yield(ton/ha)",
    type: "text",
    name: "production_yield",
    placeholder: "Production Yield(ton/ha)",
  },
];

const StepThree = [
  {
    id: 1,
    label: "Pesticides (ton/ha)",
    type: "text",
    name: "pesticides",
    placeholder: "Pesticides (ton/ha)",
  },
  {
    id: 2,
    label: "Cultivation Impact (per kg)",
    type: "text",
    name: "pig_fertilizer",
    placeholder: "Cultivation Impact (per kg)",
  },
  {
    id: 3,
    label: "Washing Machine (kWh)",
    type: "text",
    name: "nitrogen_fertilizer",
    placeholder: "Washing Machine (kWh)",
  },
  {
    id: 4,
    label: "Washing Machine Capacity (kg/hr)",
    type: "text",
    name: "phosphorus_fertilizer",
    placeholder: "Washing Machine Capacity (kg/hr)",
  },
  {
    id: 5,
    label: "Water Consumption (kg/kg)",
    type: "text",
    name: "potassium_fertilizer",
    placeholder: "Water Consumption (kg/kg)",
  },
  {
    id: 5,
    label: "Farm Gate Impact per kg",
    type: "text",
    name: "potassium_fertilizer",
    placeholder: "Farm Gate Impact per kg",
  },
];

const StepTwo = [
  {
    id: 1,
    label: "Fertilizer - Cattle (ton/ha)",
    type: "text",
    name: "cattle_fertilizer",
    placeholder: "Cattle (ton/ha)",
  },
  {
    id: 2,
    label: "Fertilizer - Pic (ton/ha)",
    type: "text",
    name: "pig_fertilizer",
    placeholder: "Pig (ton/ha)",
  },
  {
    id: 3,
    label: "Fertilizer - Nitrogen (ton/ha)",
    type: "text",
    name: "nitrogen_fertilizer",
    placeholder: "Cattle (ton/ha)",
  },
  {
    id: 4,
    label: "Fertilizer - Phosphorus (ton/ha)",
    type: "text",
    name: "phosphorus_fertilizer",
    placeholder: "Phosphorus (ton/ha)",
  },
  {
    id: 5,
    label: "Fertilizer - Potassium (ton/ha)",
    type: "text",
    name: "potassium_fertilizer",
    placeholder: "Potassium (ton/ha)",
  },
];

const ProductFields = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

const UserForm = [
  {
    id: 1,
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: 2,
    label: "Name and surname",
    type: "text",
    placeholder: "John Doe",
  },
  {
    id: 3,
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: 4,
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: 5,
    label: "Password",
    type: "password",
  },
  {
    id: 6,
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
  {
    id: 7,
    label: "Country",
    type: "text",
    placeholder: "USA",
  },
];

const cultColumns = [
  { field: "supplier_name", headerName: "Supplier", width: 130 },
  { field: "product", headerName: "Product", width: 130 },
  { field: "Cultivar", headerName: "Cultivar", width: 130 },
  { field: "Country", headerName: "country", width: 130 },
  { field: "carbon_footprint", headerName: "Carbon", width: 130 },
];
const cultivationRows = [
  {
    id: 1,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 2,
    supplier_name: "Supplier2",
    product: "Orange",
    Cultivar: "Australian",
    Country: "Australia",
    carbon_footprint: "0.004",
  },
  {
    id: 3,
    supplier_name: "Supplier3",
    product: "Mango",
    Cultivar: "Alfonso",
    Country: "Sri Lanka",
    carbon_footprint: "0.008",
  },
  {
    id: 4,
    supplier_name: "Supplier4",
    product: "Banan",
    Cultivar: "ATC",
    Country: "Indonisia",
    carbon_footprint: "0.0015",
  },
  {
    id: 5,
    supplier_name: "Supplier5",
    product: "Avacardo",
    Cultivar: "Green",
    Country: "Malaysia",
    carbon_footprint: "0.123",
  },
  {
    id: 6,
    supplier_name: "Supplier6",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 7,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 8,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 9,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 10,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 11,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
  {
    id: 12,
    supplier_name: "Supplier1",
    product: "Apple",
    Cultivar: "FUJI",
    Country: "Japan",
    carbon_footprint: "0.002",
  },
];

const MENU_SIDE_BAR = [
  {
    mainTitle: "",
    subTitle: [
      {
        name: "dashboard",
        titleName: "Dashboard",
        path: "",
        icon: images.Dashboard,
        activeIcon: images.LightDashboard
      },
      {
        name: "product",
        titleName: "Products",
        path: "product",
        icon: images.ProductImage,
        activeIcon: images.LightProductImage
      },
      {
        name: "ingredient",
        titleName: "Ingredients",
        path: "ingredient",
        icon: images.IngredientImage,
        activeIcon: images.LightIngredientImage
      },
      {
        name: "supplier",
        titleName: "Suppliers",
        path: "supplier",
        icon: images.SupplierImage,
        activeIcon: images.LightSupplierImage
      },
      {
        name: "facility",
        titleName: "Facility",
        path: "facility",
        icon: images.transportImage,
        activeIcon: images.LightTransportImage
      },
      {
        name: "drawing",
        titleName: "Mapping",
        path: "drawing",
        icon: images.IngredientSummery,
        activeIcon: images.LightIngredientSummery
      },
      {
        name: "drawingIngredient",
        titleName: "Ingredient Mapping",
        path: "drawing/drawing-ingredient",
        icon: images.IngredientSummery,
        activeIcon: images.LightIngredientSummery
      },
      {
        name: "productSummary",
        titleName: "Product Summary",
        path: "product-summary",
        icon: images.productSummery,
        activeIcon: images.LightProductSummery
      },
      {
        name: "clientList",
        titleName: "Clients",
        path: "client-list",
        icon: images.ClientImage,
        activeIcon: images.LightClientImage
      },
      {
        name: "drawingRequests",
        titleName: "Drawing Requests",
        path: "drawing-requests",
        icon: images.Notification,
        activeIcon: images.Notification,
      },
    ],
  },
];

const HEADER_MENU_BAR = [
  {
    key: "1",
    text: "Company",
    value: "company",
    path: "/company"
  },
  {
    key: "2",
    text: "Users",
    value: "user",
    path: "/users"
  },
  {
    key: "3",
    text: "Static Config",
    value: "staticConfiguration",
    path: "/settings/static-configuration"
  }
]

const ProductTableCol = [
  {
    name: "Name",
    col: 4,
  },
  {
    name: "Product Type",
    col: 2,
  },
  {
    name: "Sales Unit",
    col: 3,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "Register Number",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const CompanyTableCol = [
  {
    name: "Name ",
    col: 3,
  },
  {
    name: "Email",
    col: 4,
  },
  {
    name: "Status",
    col: 4,
  },
  {
    name: "",
    col: 5,
  },
];

const UserTableCol = [
  {
    name: "Pic",
    col: 1,
  },
  {
    name: "First Name",
    col: 2,
  },
  {
    name: "Last Name",
    col: 2,
  },
  {
    name: "Email",
    col: 2,
  },
  {
    name: "Status",
    col: 1,
  },
  {
    name: "Role",
    col: 1,
  },
  {
    name: "",
    col: 3,
  },
];

const ProcessDetailCol = [
  {
    name: "Product Name",
    col: 7,
  },
  {
    name: "Impact",
    col: 6,
  },
  {
    name: "",
    col: 3,
  },
];
const IngredientTableCol = [
  {
    name: "Produce Type",
    col: 14,
  },
  {
    name: "",
    col: 2,
  },
];

const CultivationTableCol = [
  {
    name: "Ingredient Name",
    col: 6,
  },
  {
    name: "Supplier Name",
    col: 8,
  },
  {
    name: "",
    col: 2,
  },
];

const ProductIngredientTableColView = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Supplier Name",
    col: 4,
  },
  {
    name: "Unit Size",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const ProductIngredientTableColViewEdit = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Supplier Name",
    col: 4,
  },
  {
    name: "Unit Size",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 3,
  },
];

const IngredientTableColView = [
  {
    name: "Ingredient Name",
    col: 3,
  },
  {
    name: "Product Name(s)",
    col: 3,
  },
  {
    name: "Supplier Name(s)",
    col: 3,
  },
  {
    name: "",
    col: 7,
  },
];

const ViewIngredientProductTableCol = [
  {
    name: "Product Name",
    col: 10,
  },
];

const ClientTableCol = [
  {
    name: "Client Name",
    col: 7,
  },
  {
    name: "Product Name",
    col: 7,
  },
  {
    name: "",
    col: 3,
  },
];

const ClientListTableCol = [
  {
    name: "Client Name",
    col: 6,
  },
  {
    name: "Email",
    col: 6,
  },
  {
    name: "Phone Number",
    col: 4,
  },
];

const TransportTableCol = [
  {
    name: "Product Name",
    col: 4,
  },
  {
    name: "Client Name",
    col: 4,
  },
  {
    name: "Process Type",
    col: 2,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const AddIngredientTableCol = [
  {
    name: "Ingredient Name",
    col: 6,
  },
  {
    name: "Ingredient Type",
    col: 2,
  },
  {
    name: "Data Type",
    col: 2,
  },
  {
    name: "Unit Size",
    col: 1,
  },
  {
    name: "Unit Type",
    col: 1,
  },
  {
    name: "EAN Barcode",
    col: 3,
  },
  {
    name: "",
    col: 1,
  },
];

const SupplierListIngredientEditCol = [
  {
    name: "Supplier Name",
    col: 8,
  },
  {
    name: "Type",
    col: 3,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const ProductFilterData = [
  { key: Math.random(), text: "All", value: "All" },
  { key: Math.random(), text: "Farm Product", value: "Farm Product" },
  { key: Math.random(), text: "Processed", value: "Processed" },
  { key: Math.random(), text: "Custom/Filter", value: "Custom/Filter" },
];

const UserFilterData = [
  { key: Math.random(), text: "All", value: "All", skip: false },
  {
    key: Math.random(),
    text: "Super Admin",
    value: "Farm Product",
    skip: false,
  },
  { key: Math.random(), text: "Admin", value: "Processed", skip: false },
];

const HarvestSeasonData = [
  { key: Math.random(), text: "Unknown", value: "Unknown" },
  { key: Math.random(), text: "Spring", value: "Spring" },
  { key: Math.random(), text: "Summer", value: "Summer" },
  { key: Math.random(), text: "Fall", value: "Fall" },
  { key: Math.random(), text: "Winter", value: "Winter" },
];

const FertilizerNameData = [
  { key: 1, text: "Manure-General", value: "Manure-General", name: "general" },
  { key: 2, text: "Manure-Cattle", value: "Manure-Cattle", name: "cattle" },
  { key: 3, text: "Manure-Poultry", value: "Manure-Poultry", name: "poultry" },
  { key: 4, text: "Manure-Pig", value: "Manure-Pig", name: "pig" },
  { key: 5, text: "Nitrogen (N)", value: "Nitrogen (N)", name: "nitrogen" },
  {
    key: 6,
    text: "Phosphorus (P)",
    value: "Phosphorus (P)",
    name: "phosphorus",
  },
  { key: 7, text: "Potassium (K)", value: "Potassium (K)", name: "potassium" },
];

const FARMING_TYPES = [
  { key: 0, text: "Unknown", value: "Unknown" },
  { key: 1, text: "Organic", value: "Organic" },
  { key: 2, text: "Conventional", value: "Conventional" },
];

const FIELD_TYPES = [
  { key: 0, text: "Unknown", value: "Unknown" },
  { key: 1, text: "Open field", value: "OpenField" },
  { key: 2, text: "Greenhouse", value: "Greenhouse" },
];

const CULTIVATION_TYPES = [
  { key: 0, text: "Supplier", value: "Supplier" },
  { key: 1, text: "Generic", value: "Generic" },
];

const PesticideNameData = [
  { key: 1, text: "General", value: "General", name: "general" },
  { key: 2, text: "Insecticide", value: "Insecticide", name: "insecticide" },
  { key: 3, text: "Fungicide", value: "Fungicide", name: "fungicide" },
  { key: 4, text: "Herbicide", value: "Herbicide", name: "herbicide" },
];

const PesticideTableHeader = [
  {
    name: "Pesticide Name",
    col: 6,
  },
  {
    name: "kg/ha",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const IrrigationTableHeader = [
  {
    name: "Irrigation Method",
    col: 3,
  },
  {
    name: "Power Source",
    col: 3,
  },
  {
    name: "Water Amount (m3)",
    col: 3,
  },
  {
    name: "Applied Portion (%)",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const LandUsageTableHeader = [
  {
    name: "Year of Change",
    col: 3,
  },
  {
    name: "Allocation (%)",
    col: 3,
  },
  {
    name: "Land usage from",
    col: 3,
  },
  {
    name: "Land usage to",
    col: 3,
  },
  {
    name: "",
    col: 4,
  },
];

const EnergySourceData = [
  {
    name: "Energy Source",
    col: 4,
  },
  {
    name: "Energy Used ",
    col: 4,
  },
  {
    name: "Energy Unit type",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const FertilizerTableHeader = [
  {
    name: "Fertilizer Name",
    col: 6,
  },
  {
    name: "kg/ha",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const ProcessintTypesNameData = [
  { key: Math.random(), text: "Sorting", value: "Sorting" },
  { key: Math.random(), text: "Washing", value: "Washing" },
  { key: Math.random(), text: "Filling", value: "Filling" },
  { key: Math.random(), text: "Cutting", value: "Cutting" },
  { key: Math.random(), text: "Crushing", value: "Crushing" },
  { key: Math.random(), text: "Balancing", value: "Balancing" },
  { key: Math.random(), text: "Boiling", value: "Boiling" },
  { key: Math.random(), text: "Can Filling", value: "Can Filling" },
  { key: Math.random(), text: "Cooking", value: "Cooking" },
  { key: Math.random(), text: "Electric Boiling", value: "Electric Boiling" },
  { key: Math.random(), text: "Steaming", value: "Steaming" },
  { key: Math.random(), text: "Slicing", value: "Slicing" },
  { key: Math.random(), text: "Sterilization", value: "Sterilization" },
];

const PackagingMaterialNameData = [
  { key: Math.random(), text: "Glass", value: "General" },
  { key: Math.random(), text: "Aluminium", value: "Aluminium" },
  { key: Math.random(), text: "Cork", value: "Cork" },
  { key: Math.random(), text: "PET", value: "PET" },
  { key: Math.random(), text: "LDPE", value: "LDPE" },
  { key: Math.random(), text: "PE", value: "PE" },
  {
    key: Math.random(),
    text: "Boardbox (cardboard box)",
    value: "Boardbox (cardboard box)",
  },
  { key: Math.random(), text: "Paper", value: "Paper" },
];

const EnergyTypeListData = [
  { key: Math.random(), text: "Local Grid", value: "Local Grid" },
  { key: Math.random(), text: "Solar", value: "Solar" },
  { key: Math.random(), text: "Wind", value: "Wind" },
];

const IngredientContents = [
  {
    name: "Ingredient 1",
    content: "",
    unit: "None",
  },
  {
    name: "Ingredient 2",
    content: "",
    unit: "Kg",
  },
  {
    name: "Ingredient 3",
    content: "",
    unit: "Litres",
  },
  {
    name: "Ingredient 4",
    content: "",
    unit: "Kg.L",
  },
  {
    name: "Ingredient 5",
    content: "",
    unit: "kWh",
  },
];

const SipContents = [
  {
    name: "Manufacturer",
    value: "",
    unit: "None",
    typeName: "manufacturer",
  },
  {
    name: "Model Number",
    value: "",
    unit: "None",
    typeName: "modelNumber",
  },
  {
    name: "Energy Consumption",
    value: "",
    unit: "kWh",
    typeName: "energyConsumption",
  },
  {
    name: "Steam Pressure",
    value: "",
    unit: "bar",
    typeName: "steamPressure",
  },
  {
    name: "Steam Flow",
    value: "",
    unit: "kg/hr",
    typeName: "steamFlow",
  },
  {
    name: "Chemical use",
    value: "",
    unit: "None",
    typeName: "chemicalUse",
  },
  {
    name: "Chemical Concentration",
    value: "",
    unit: "%",
    typeName: "chemicalConcentration",
  },
  {
    name: "Chemical Consumption",
    value: "",
    unit: "L",
    typeName: "chemicalConsumption",
  },
  {
    name: "Water Consumption",
    value: "",
    unit: "L",
    typeName: "waterConsumption",
  },
  {
    name: "Waste water",
    value: "",
    unit: "L",
    typeName: "wasteWater",
  },
];

const ProcessContents = [
  {
    name: "Process 1",
    content: "",
    unit: "Bar",
  },
  {
    name: "Process 2",
    content: "",
    unit: "Kg/h",
  },
  {
    name: "Process 3",
    content: "",
    unit: "Litres",
  },
  {
    name: "Process 4",
    content: "",
    unit: "Kg.L",
  },
];

const ProductTableContent = [
  {
    name: {
      id: "122",
    },
    climate: 3,
    reg: 123,
  },
  {
    name: "Name",
    climate: 3,
    reg: 123,
  },
  {
    name: "Name",
    climate: 3,
    reg: 123,
  },
  {
    name: "Name",
    climate: 3,
    reg: 123,
  },
];

const ClientProductTableContent = [
  {
    name: "Carrot",
    type: "New",
    impact: 0.3,
  },
  {
    name: "Carrot",
    type: "New",
    impact: 0.3,
  },
  {
    name: "Carrot",
    type: "New",
    impact: 0.3,
  },
  {
    name: "Carrot",
    type: "New",
    impact: 0.3,
  },
];

const CultivationListData = [
  {
    name: "Carrot",
    climate: 0.5,
  },
  {
    name: "Lime",
    climate: 0.6,
  },
  {
    name: "Beetroot",
    climate: 0.7,
  },
  {
    name: "Ginger",
    climate: 0.8,
  },
];

const ClentListData = [
  {
    client: "Carrot Farm (PVT) Ltd",
    product: "Carrot",
    climate: 0.5,
  },
  {
    client: "Coca Cola (PVT) Ltd",
    product: "Lemonade",
    climate: 0.5,
  },
  {
    client: "McDonalds (PVT) Ltd",
    product: "Burger",
    climate: 0.5,
  },
  {
    client: "Nero Fish (PVT) Ltd",
    product: "Canned Fish",
    climate: 0.5,
  },
];

const switchSize = {
  mini: "mini",
  tiny: "tiny",
  small: "small",
  medium: "medium",
  large: "large",
  big: "big",
  huge: "huge",
  massive: "massive",
};

const ProductFilters = ["All", "Farm Product", "Processed", "Custom / Filter"];
const ProductIngredientCol = [
  {
    name: "Ingredient Name",
    col: 3,
  },
  {
    name: "Supplier Name",
    col: 3,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "",
    col: 7,
  },
];

const CommonCol = [
  {
    name: "Name",
    col: 8,
  },
  {
    name: "Content",
    col: 5,
  },
  {
    name: "Unit",
    col: 3,
  },
];

const SipCol = [
  {
    name: "Name",
    col: 8,
  },
  {
    name: "Value",
    col: 5,
  },
  {
    name: "Unit",
    col: 3,
  },
];

const ProductProcessCol = [
  {
    name: "Process Name",
    col: 3,
  },
  {
    name: "Processing Type",
    col: 3,
  },
  {
    name: "Climate Impact",
    col: 3,
  },
  {
    name: "",
    col: 7,
  },
];

const ViewIngredientSupplierCol = [
  {
    name: "Supplier Name",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 2,
  },
  {
    name: "Unit Size",
    col: 2,
  },
  {
    name: "EAN Barcode",
    col: 2,
  },
  {
    name: "Data Type",
    col: 2,
  },
  {
    name: "Ingredient Type",
    col: 3,
  },
];

const PackagingCol = [
  {
    name: "Product Name",
    col: 3,
  },
  {
    name: "Unit Size and Type",
    col: 3,
  },
  {
    name: "Barcode",
    col: 2,
  },
  {
    name: "Climate Impact",
    col: 2,
  },
  {
    name: "",
    col: 6,
  },
];

const PackagingColDetails = [
  {
    name: "Product Name",
    col: 3,
  },
  {
    name: "Unit Size ",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 4,
  },
  {
    name: "EAN Barcode",
    col: 6,
  },
];

const ClientProductListCol = [
  {
    name: "Product Name",
    col: 8,
  },
  {
    name: "Sales Unit",
    col: 8,
  },
];

const ProcessTab = [
  {
    name: "Recipe",
    route: "/process/recipe",
    size: 3,
  },
  {
    name: "Processing",
    route: "/process/processing",
    size: 3,
  },
  {
    name: "Packaging",
    route: "/process/packing",
    size: 2,
  },
  {
    name: "Storage",
    route: "/process/storage",
    size: 2,
  },
  {
    name: "Energy",
    route: "/process/energy",
    size: 2,
  },
  {
    name: "Transportation",
    route: "/process/transportation",
    size: 2,
  },
  {
    name: "Confirmation",
    route: "/process/confirmation",
    size: 2,
  },
];

const CultivationTab = [
  {
    name: "Cultivar",
    route: "/cultivation/cultivar",
    size: 2,
  },
  {
    name: "Field",
    route: "/cultivation/field",
    size: 2,
  },
  {
    name: "Processing",
    route: "/cultivation/processing",
    size: 2,
  },
  {
    name: "Packaging",
    route: "/cultivation/packaging",
    size: 2,
  },
  {
    name: "Energy",
    route: "/cultivation/energy",
    size: 2,
  },
  {
    name: "Storage",
    route: "/cultivation/storage",
    size: 2,
  },
  {
    name: "Transportation",
    route: "/cultivation/transportation",
    size: 2,
  },
  {
    name: "Confirmation",
    route: "/cultivation/confirmation",
    size: 2,
  },
];

const SIP_DATA = [
  {
    name: "Manufacturer",
    unit: "",
  },
  {
    name: "Model Number",
    unit: "",
  },
  {
    name: "Frequency",
    unit: "hr/day",
  },
  {
    name: "Energy Consumption",
    unit: "kWh",
  },
  {
    name: "Water Consumption",
    unit: "Liters",
  },
  {
    name: "Steam Pressure",
    unit: "bar",
  },
  {
    name: "Steam Flow",
    unit: "kg / hr",
  },
  {
    name: "Chemical use",
    unit: "Type",
  },
  {
    name: "Chemical Concentration",
    unit: "hr/day",
  },
  {
    name: "Chemical Consumption",
    unit: "L / hr",
  },
];

const SampleIngredientData = [
  {
    name: "Ingredient 1",
    unit: "",
  },
  {
    name: "Ingredient 2",
    unit: "",
  },
  {
    name: "Ingredient 3",
    unit: "hr/day",
  },
  {
    name: "Ingredient 4",
    unit: "kWh",
  },
  {
    name: "Ingredient 5",
    unit: "Liters",
  },
  {
    name: "Ingredient 6",
    unit: "bar",
  },
];

const BreadcrumbProcess = [
  { key: "Product", content: "Product", link: true },
  { key: "Recipe", content: "Recipe", link: true },
  { key: "Processing impact", content: "Processing impact", link: true },
  { key: "Packaging", content: "Packaging", link: true },
  { key: "Storage", content: "Storage", link: true },
  { key: "Energy", content: "Energy", link: true },
  { key: "Confirmation", content: "Confirmation", link: true },
];

const BreadcrumbTransportation = [
  { key: "Product", content: "Product", link: true },
  { key: "Transportation", content: "Transportation", link: true },
];

const BreadcrumbCultivation = [
  { key: "Ingredient", content: "Ingredient", link: true },
  { key: "Cultivar", content: "Cultivar", link: true },
  { key: "Field", content: "Field", link: true },
  { key: "Processing", content: "Processing", link: true },
  { key: "Packaging", content: "Packaging", link: true },
  { key: "Energy", content: "Energy", link: true },
  { key: "Storage", content: "Storage", link: true },
  { key: "Transportation", content: "Transportation", link: true },
  { key: "Confirmation", content: "Confirmation", link: true },
  { key: "Next Ingredient", content: "Next Ingredient", link: true },
];

const FieldConfirmCol = [
  {
    name: "Fertilizer Name",
    col: 10,
  },
  {
    name: "Quantity (kg/ha)",
    col: 5,
  },
];

const PesticideCol = [
  {
    name: "Pesticide Name",
    col: 10,
  },
  {
    name: "Quantity (kg/ha)",
    col: 5,
  },
];

const PackagingConfirmCol = [
  {
    name: "Material Name",
    col: 10,
  },
  {
    name: "Weight",
    col: 5,
  },
];

const PackagingMachineConfirmCol = [
  {
    name: "Packaging Method",
    col: 10,
  },
  {
    name: "Energy(kWh/kg)",
    col: 5,
  },
];

const ProcessingConfirmCol = [
  {
    name: "Process",
    col: 10,
  },
  {
    name: "Processing Type",
    col: 3,
  },
  {
    name: "Machine Model No",
    col: 3,
  },
  {
    name: "Facility Id",
    col: 3,
  },
];

const EnergyConfirmCol = [
  {
    name: "Type",
    col: 10,
  },
  {
    name: "Precentage",
    col: 5,
  },
];

const StorageConfirmCol = [
  {
    name: "Warehouse Name",
    col: 8,
  },
  {
    name: "Warehouse Size",
    col: 4,
  },
  {
    name: "Weight",
    col: 4,
  },
];

const TransportationConfirmCol = [
  {
    name: "Transport Type",
    col: 10,
  },
  {
    name: "Average Distance",
    col: 5,
  },
];

const TransportationExternal = [
  {
    name: "Transport Type",
    col: 10,
  },
  {
    name: "Distance",
    col: 5,
  },
];

const ProcessRecipe = [
  {
    name: "Ingredient Name",
    col: 3,
  },
  {
    name: "Content",
    col: 3,
  },
  {
    name: "Percentage",
    col: 3,
  },
];

const Processing = [
  {
    name: "Process Name",
    col: 1,
  },
  {
    name: "Processing Type",
    col: 1,
  },
  {
    name: "input",
    col: 1,
  },
  {
    name: "output",
    col: 1,
  },
  {
    name: "capacity",
    col: 1,
  },
];

const Packaging = [
  {
    name: "Meaterial Weight",
    col: 2,
  },
  {
    name: "Package Material",
    col: 2,
  },
  {
    name: "Package Name",
    col: 2,
  },
];

const Storage = [
  {
    name: "Warehouse Name",
    col: 2,
  },
  {
    name: "Warehouse Size",
    col: 2,
  },
  {
    name: "Temperature",
    col: 2,
  },
  {
    name: "Electricity",
    col: 2,
  },
];

const Energy = [
  {
    name: "Energy Type",
    col: 2,
  },
  {
    name: "kWh",
    col: 2,
  },
  {
    name: "Percentage",
    col: 2,
  },
];

const Transportation = [
  {
    name: "Transport Type",
    col: 2,
  },
  {
    name: "Average Distance",
    col: 2,
  },
];

const EnergyTable = [
  {
    name: "Energy Type",
    col: 3,
  },
  {
    name: "KWh",
    col: 3,
  },
  {
    name: "Percentage",
    col: 3,
  },
];

const ProcessFlowCol = [
  {
    name: "Product Name",
    col: 7,
  },
  {
    name: "Sales Unit Name",
    col: 4,
  },
  {
    name: "",
    col: 7,
  },
];

const ProductUnitTable = [
  {
    name: "Unit Name",
    col: 3,
  },
  {
    name: "Unit Size",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 3,
  },
  {
    name: "EAN Number",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const ClientTransportMethodsTable = [
  {
    name: "Transport Type",
    col: 3,
  },
  {
    name: "From",
    col: 3,
  },
  {
    name: "To",
    col: 3,
  },
  {
    name: "Distance (Km)",
    col: 2,
  },
  {
    name: "Weight (Kg)",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const PROCESSING_TYPES = [
  {
    key: 1,
    text: "Sorting",
    value: "Sorting",
  },
  {
    key: 2,
    text: "washing",
    value: "washing",
  },
  {
    key: 3,
    text: "filling",
    value: "filling",
  },
  {
    key: 4,
    text: "cutting",
    value: "cutting",
  },
  {
    key: 5,
    text: "crushing",
    value: "crushing",
  },
  {
    key: 6,
    text: "blanching",
    value: "blanching",
  },
  {
    key: 7,
    text: "boiling",
    value: "boiling",
  },
  {
    key: 8,
    text: "can filling",
    value: "can filling",
  },
  {
    key: 9,
    text: "cooking",
    value: "cooking",
  },
  {
    key: 10,
    text: "electric boiling",
    value: "electric boiling",
  },
  {
    key: 11,
    text: "seaming",
    value: "seaming",
  },
  {
    key: 12,
    text: "slicing",
    value: "slicing",
  },
  {
    key: 13,
    text: "sterilization",
    value: "sterilization",
  },
  {
    key: 14,
    text: "conveyor belt",
    value: "conveyor belt",
  },
  {
    key: 15,
    text: "Balance tank",
    value: "Balance tank",
  },
];

const UNIT_TYPES = [
  {
    key: 1,
    text: "L",
    value: "L",
  },
  {
    key: 2,
    text: "ml",
    value: "ml",
  },
  {
    key: 3,
    text: "g",
    value: "g",
  },
  {
    key: 4,
    text: "kg",
    value: "kg",
  },
  {
    key: 5,
    text: "ton",
    value: "ton",
  },
];

const HEAT_SOURCE_UNIT_TYPES = [
  {
    key: 1,
    text: "kg",
    value: "kg",
  },
  {
    key: 2,
    text: "L",
    value: "L",
  },
  {
    key: 3,
    text: "gm3",
    value: "gm3",
  },
];

const REFRIGERANT_UNIT_TYPES = [
  {
    key: 1,
    text: "kg",
    value: "kg",
  },
  {
    key: 2,
    text: "L",
    value: "L",
  }
];

const SupplierIngredientTable = [
  {
    name: "Ingredient Name",
    col: 4,
  },
  {
    name: "Unit Size",
    col: 3,
  },
  {
    name: "Unit Type",
    col: 3,
  },
  {
    name: "EAN Number",
    col: 3,
  },
  {
    name: "",
    col: 3,
  },
];

const ProductSummaryTableCol = [
  {
    name: "Indicator",
    col: 6,
  },
  {
    name: "Measure",
    col: 6,
  },
  {
    name: "Unit per kg",
    col: 3,
  },
  {
    name: "",
    col: 1,
  },
];

const ProductSummaryHistoryTableCol = [
  {
    name: "Date",
    col: 6,
  },
  {
    name: "Climate Impact",
    col: 4,
  },
  {
    name: "",
    col: 3,
  },
];

const ProductSummaryData = [
  {
    indicator: "Acidification",
    measure: "mol",
    unit: "H+ eq",
  },
  {
    indicator: "Climate change",
    measure: "kg",
    unit: " CO2 eq",
  },
  {
    indicator: "Ecotoxicity, freshwater ",
    measure: "CTUe",
    unit: "P eq",
  },
  {
    indicator: "Eutrophication, freshwater",
    measure: "kg",
    unit: " CO2 eq",
  },
  {
    indicator: "Eutrophication, marine ",
    measure: "kg",
    unit: "N eq",
  },
  {
    indicator: "Eutrophication, terrestrial  ",
    measure: "mol",
    unit: " N eq",
  },
  {
    indicator: "Human toxicity, cancer",
    measure: "CTUh",
    unit: "H+ eq",
  },
  {
    indicator: "Human toxicity, non-cancer",
    measure: "CTUh",
    unit: " CO2 eq",
  },
  {
    indicator: "Acidification",
    measure: "mol",
    unit: "H+ eq",
  },
  {
    indicator: "Ionising radiation",
    measure: "kBq",
    unit: "U-235 eq",
  },
  {
    indicator: "Acidification",
    measure: "mol",
    unit: "H+ eq",
  },
  {
    indicator: "Land use",
    measure: "Pt",
    unit: " CO2 eq",
  },
  {
    indicator: "Ozone depletion",
    measure: "kg",
    unit: "CFC11 eq",
  },
  {
    indicator: "Particulate matter",
    measure: "disease inc",
    unit: " CO2 eq",
  },
  {
    indicator: "Photochemical ozone formation",
    measure: "kg",
    unit: "NMVOC eq",
  },
  {
    indicator: "Resource use, fossils",
    measure: "MJ",
    unit: " CO2 eq",
  },
  {
    indicator: "Resource use, minerals and metals",
    measure: "kg",
    unit: "Sb eq",
  },
  {
    indicator: "Water use",
    measure: "m3",
    unit: "depriv",
  },
];

const CycleStage = [
  {
    title: "Agriculture",
    icon: MdAgriculture,
  },
  {
    title: "Transformation",
    icon: FaLeaf,
  },
  {
    title: "Transformation",
    icon: MdBackpack,
  },
  {
    title: "Transformation",
    icon: MdAgriculture,
  },
];

const IngredientImpact = [
  {
    title: "Milk",
  },
  {
    title: "Strawberry",
  },
  {
    title: "White Suger",
  },
  {
    title: "Other Steps",
  },
];

const ProductSummaryTitle = [
  {
    text: "Ingredient Impact",
    skip: true,
  },
  {
    text: "Supply Chain Overview",
    skip: true,
  },
  {
    text: "KPI",
    skip: true,
  },
  {
    text: "Impact Reduction",
    skip: true,
  },
  {
    text: "Reports",
    skip: true,
  },
  {
    text: "Certificates",
    skip: true,
  },
  {
    text: "Product Simulation",
    skip: false,
  },
  {
    text: "Packaging Simulation",
    skip: false,
  },
  {
    text: "Product History",
    skip: false,
  },
];

const PACKAGING_MATERIALS = [
  {
    key: 1,
    text: "Glass",
    value: "Glass",
  },
  {
    key: 2,
    text: "Aluminum",
    value: "Aluminum",
  },
  {
    key: 3,
    text: "Cork",
    value: "Cork",
  },
  {
    key: 4,
    text: "LDPE",
    value: "LDPE",
  },
  {
    key: 5,
    text: "PE",
    value: "PE",
  },
  {
    key: 5,
    text: "Boardbox",
    value: "Boardbox",
  },
  {
    key: 5,
    text: "Paper",
    value: "Paper",
  },
];

const TransportationType = [
  {
    key: 0,
    text: "freight, lorryfreight (Road)",
    value: "freight, lorryfreight (Road)",
  },
  {
    key: 1,
    text: "lorry with refrigeration machinefreight (Road)",
    value: "lorry with refrigeration machinefreight (Road)",
  },
  {
    key: 2,
    text: "light commercial vehicle (Road)",
    value: "light commercial vehicle (Road)",
  },
  {
    key: 3,
    text: "passenger car (Road)",
    value: "passenger car (Road)",
  },
  {
    key: 4,
    text: "electric bicycle (Road)",
    value: "electric bicycle (Road)",
  },
  {
    key: 5,
    text: "motor scooter (Road)",
    value: "motor scooter (Road)",
  },
  {
    key: 6,
    text: "freight train (Road)",
    value: "freight train (Road)",
  },
  {
    key: 7,
    text: "freight, sea, container ship (water)",
    value: "freight, sea, container ship (water)",
  },
  {
    key: 8,
    text: "freight, ferry  (water)",
    value: "freight, ferry  (water)",
  },
  {
    key: 9,
    text: "freight, inland waterways, barge  (water)",
    value: "freight, inland waterways, barge  (water)",
  },
  {
    key: 10,
    text: "freight, aircraft  (Air)",
    value: "freight, aircraft  (Air)",
  },
];

const UserType = [
  { key: 1, text: "Admin", value: "Admin" },
  { key: 2, text: "Super Admin", value: "Super Admin" },
  { key: 3, text: "Manager", value: "Manager" },
];

const newNodes = [
  {
    id: "1",
    image: images.ingredients_icon,
    cardHeader: "Ingredients",
    cardExtra: "Ingredients types",
  },
  {
    id: "2",
    image: images.transport_icon,
    cardHeader: "Transportation",
    cardExtra: "Transportation by Flight",
  },
  {
    id: "3",
    image: images.process_icon,
    cardHeader: "Process",
    cardExtra: "Precessed Ingredients",
  },
  {
    id: "4",
    image: images.warehouse_icon,
    cardHeader: "Warehouse",
    cardExtra: "Ingredients types",
  },
  {
    id: "5",
    image: images.package_icon,
    cardHeader: "Packing",
    cardExtra: "Ingredients types",
  },
  {
    id: "6",
    image: images.client_icon,
    cardHeader: "Client",
    cardExtra: "Ingredients types",
  },
];

const CultivationEnergyTitle = [
  {
    title: "Energy Type",
    computer: 7,
  },
  {
    title: "kWh",
    computer: 4,
  },
  {
    title: "Percentage",
    computer: 5,
  },
];

const CultivationSinglePackagingTitle = [
  {
    title: "Machine Name",
    computer: 7,
  },
  {
    title: "Energy(kWh/kg)",
    computer: 3,
  },
  {
    title: "Waste(kg/hr)",
    computer: 3,
  },
  {
    title: "Capacity(pk/hr)",
    computer: 3,
  },
];

const CultivationPackagingTitle = [
  {
    title: "Packaging Name",
    computer: 7,
  },
  {
    title: "Packaging Material",
    computer: 4,
  },
  {
    title: "Material Weight(kg/unit)",
    computer: 5,
  },
];

const CultivationPackagingView = [
  {
    name: "Packaging Name",
    col: 4,
  },
  {
    name: "Packaging Material",
    col: 4,
  },
  {
    name: "Material Weight(kg/unit)",
    col: 3,
  },
  {
    name: "Material Cost per Unit",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const CultivationPackagingMethodView = [
  {
    name: "Packaging Step",
    col: 3,
  },
  {
    name: "Packaging Machinary",
    col: 3,
  },
  {
    name: "Packaging Level",
    col: 2,
  },
  {
    name: "Energy",
    col: 1,
  },
  {
    name: "Capacity(pk/hr)",
    col: 1,
  },
  {
    name: "Waste(pk/hr)",
    col: 1,
  },
  {
    name: "",
    col: 2,
  },
];

const CultivationFieldFertilizerTitle = [
  {
    title: "Fertilizer Name",
    computer: 10,
  },
  {
    title: "Quantity(kg/ha)",
    computer: 6,
  },
];

const ProductTitle = [
  {
    title: "Product Name*",
    computer: 10,
  },
];

const CultivationInternalTransportationTitle = [
  {
    title: "Average Distance from Filed(s) to Facility (km)",
    computer: 6,
  },
  {
    title: "Transportation Type",
    computer: 6,
  },
];

const CultivationFromTransportationTitle = [
  {
    title: "Transportation Type",
    computer: 6,
  },
  {
    title: "From",
    computer: 3,
  },
  {
    title: "To",
    computer: 3,
  },
  {
    title: "Distance (KM)",
    computer: 4,
  },
];

const CultivationFieldPesticidesTitle = [
  {
    title: "Pesticide Name",
    computer: 11,
  },
  {
    title: "Quantity(kg/ha)",
    computer: 5,
  },
];

const FacilityTableCol = [
  {
    name: "Facility Name",
    col: 3,
  },
  {
    name: "Facility Type",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const FacilityEnergyTableCol = [
  {
    name: "Energy Type",
    col: 6,
  },
  {
    name: "KWH",
    col: 5,
  },
  {
    name: "Percentage",
    col: 5,
  },
];

const FacilityEnergyTable = [
  {
    name: "Year",
    col: 3,
  },
  {
    name: "Source",
    col: 3,
  },
  {
    name: "kWh consumed",
    col: 3,
  },
  {
    name: "Distribution",
    col: 2,
  },
  {
    name: "Total cost",
    col: 2,
  },
  {
    name: "",
    col: 3
  },
];

const FacilityHeatTable = [
  {
    name: "Year",
    col: 4,
  },
  {
    name: "Source",
    col: 4,
  },
  {
    name: "Volume",
    col: 3,
  },
  {
    name: "Total",
    col: 3,
  },
  {
    name: "",
    col: 2,
  },
];

const FacilityHeatTableWithoutEdit = [
  {
    name: "Heat Source",
    col: 4,
  },
  {
    name: "Volumes",
    col: 3,
  },
  {
    name: "Price",
    col: 3,
  },
  {
    name: "Percentage",
    col: 3,
  },
  {
    name: "Year",
    col: 3,
  },
];


const FacilityWaterTable = [
  {
    name: "Year",
    col: 3,
  },
  {
    name: "Source",
    col: 3,
  },
  {
    name: "Volume",
    col: 3,
  },
  {
    name: "Total",
    col: 2,
  },
  {
    name: "",
    col: 2,
  },
];

const FacilityWaterTableWithoutEdit = [
  {
    name: "Water Source",
    col: 4,
  },
  {
    name: "Volumes",
    col: 3,
  },
  {
    name: "Price",
    col: 3,
  },
  {
    name: "Year",
    col: 3,
  },
];

const FACILITY_TYPES = [
  {
    key: 1,
    text: "Processing Facility",
    value: "FACTORY",
  },
  {
    key: 2,
    text: "Storage Facility",
    value: "STORAGE",
  },
];

const FACILITY_OWNERSHIP = [
  {
    key: 1,
    text: "Internal",
    value: "Internal",
  },
  {
    key: 2,
    text: "External",
    value: "External",
  },
];

const PACKAGING_LEVELS = [
  {
    key: 1,
    text: "Base unit packaging",
    value: "Base unit packaging",
  },
  {
    key: 2,
    text: "Inner pack packaging",
    value: "Inner pack packaging",
  },
  {
    key: 3,
    text: "Case packaging",
    value: "Case packaging",
  },
  {
    key: 4,
    text: "Pallet packaging",
    value: "Pallet packaging",
  },
];

const drawerRoute = ["/drawer", "/drawing-ingredient"];

const InternalTransportation = [
  {
    title: "Transportation Type",
    computer: 4,
  },
  {
    title: "From",
    computer: 3,
  },
  {
    title: "Weight",
    computer: 3,
  },
  {
    title: "Unit Type",
    computer: 3,
  },
  {
    title: "To",
    computer: 3,
  },
  {
    title: "Distance (KM)",
    computer: 4,
  },
];

const ChemicalDataCol = [
  {
    name: "Chemical name",
    col: 4,
  },
  {
    name: "Chemical Amount",
    col: 4,
  },
  {
    name: "",
    col: 8,
  },
];

const TransportTypeCol = [
  {
    name: "Transportation Type",
    col: 6,
  },
  {
    name: "Distance",
    col: 6,
  },
  {
    name: "",
    col: 4,
  },
];

const WasteManagementDataCol = [
  {
    name: "Management Name",
    col: 4,
  },
  {
    name: "Management Type",
    col: 4,
  },
  {
    name: "Distribution (kg)",
    col: 4,
  },
  {
    name: "",
    col: 4,
  },
];

const DATA_TYPES = [
  { key: 0, text: "Supplier", value: "SUPPLIER" },
  { key: 1, text: "Generic", value: "GENERIC" },
];

const INGREDIENT_TYPE = [
  {
    key: 1,
    text: "RAW",
    value: "RAW",
  },
  {
    key: 2,
    text: "PROCESSED",
    value: "PROCESSED",
  },
];

const ENERGY_UNIT_TYPE = [
  {
    key: 1,
    text: "kWh",
    value: 1,
  },
  {
    key: 2,
    text: "MJ",
    value: 2,
  },
  {
    key: 3,
    text: "thm",
    value: 3,
  },
  {
    key: 4,
    text: "btu",
    value: 4,
  },
];

const PER_TYPES = [
  {
    key: "day",
    text: "day",
    value: "day",
  },
  {
    key: "week",
    text: "week",
    value: "week",
  },
  {
    key: "month",
    text: "month",
    value: "month",
  },
  {
    key: "year",
    text: "year",
    value: "year",
  }
]

const PER_WEIGHT_UNITS = [
  {
    key: "kg",
    text: "kg",
    value: "kg",
  },
  {
    key: "ton",
    text: "ton",
    value: "ton",
  }
]

const NotificationCol = [
  {
    name: "Ingredient Name",
    col: 6,
  },
  {
    name: "Company Name",
    col: 6,
  },
  {
    name: "",
    col: 2,
  },
];
const DISABLE_CLOSE_MODEL_ID = [
  "6",
  "8",
  "10",
  "11"
]

const COMMON_PROTECT_ROUTE_PATH = "/auth"

export {
  StepOne,
  StepThree,
  StepTwo,
  UserForm,
  ProductFields,
  cultColumns,
  cultivationRows,
  MENU_SIDE_BAR,
  ProductTableCol,
  ProductTableContent,
  IngredientTableCol,
  ProductFilters,
  ProductFilterData,
  switchSize,
  ProductIngredientCol,
  ProductProcessCol,
  ProcessDetailCol,
  ProcessTab,
  SIP_DATA,
  SampleIngredientData,
  BreadcrumbProcess,
  AddIngredientTableCol,
  ProcessRecipe,
  Processing,
  EnergyTable,
  ProcessFlowCol,
  ProductUnitTable,
  SupplierListIngredientEditCol,
  BreadcrumbCultivation,
  CultivationTab,
  HarvestSeasonData,
  FertilizerNameData,
  FertilizerTableHeader,
  PesticideNameData,
  PesticideTableHeader,
  ProcessintTypesNameData,
  PackagingMaterialNameData,
  EnergyTypeListData,
  FieldConfirmCol,
  ProcessingConfirmCol,
  PackagingConfirmCol,
  EnergyConfirmCol,
  CultivationListData,
  TransportTableCol,
  BreadcrumbTransportation,
  PackagingCol,
  IngredientContents,
  ProcessContents,
  SipContents,
  SipCol,
  CommonCol,
  ClientTableCol,
  ClentListData,
  ClientProductListCol,
  ClientProductTableContent,
  ViewIngredientProductTableCol,
  ViewIngredientSupplierCol,
  PackagingColDetails,
  UNIT_TYPES,
  API_URL,
  SupplierIngredientTable,
  ProductSummaryTableCol,
  ProductSummaryData,
  CycleStage,
  IngredientImpact,
  ProductSummaryTitle,
  PROCESSING_TYPES,
  PACKAGING_MATERIALS,
  TransportationType,
  Packaging,
  Storage,
  Energy,
  Transportation,
  StorageConfirmCol,
  TransportationConfirmCol,
  IngredientTableColView,
  UserTableCol,
  UserFilterData,
  UserType,
  newNodes,
  CultivationEnergyTitle,
  CultivationFieldFertilizerTitle,
  CultivationFieldPesticidesTitle,
  CultivationPackagingTitle,
  CultivationSinglePackagingTitle,
  CultivationInternalTransportationTitle,
  CultivationFromTransportationTitle,
  CultivationTableCol,
  PesticideCol,
  TransportationExternal,
  PackagingMachineConfirmCol,
  CompanyTableCol,
  ProductTitle,
  drawerRoute,
  NP_API_URL,
  NP_SOCKET_API_URL,
  THEME,
  COMPANY_LOGO,
  proOptionsForReactFlow,
  COMPANY_NAME,
  InternalTransportation,
  CultivationPackagingView,
  CultivationPackagingMethodView,
  FACILITY_TYPES,
  FacilityEnergyTable,
  FacilityTableCol,
  FARMING_TYPES,
  FIELD_TYPES,
  FacilityEnergyTableCol,
  CULTIVATION_TYPES,
  ProductIngredientTableColView,
  ClientTransportMethodsTable,
  ProductIngredientTableColViewEdit,
  PACKAGING_LEVELS,
  ChemicalDataCol,
  TransportTypeCol,
  ProductSummaryHistoryTableCol,
  WasteManagementDataCol,
  DATA_TYPES,
  INGREDIENT_TYPE,
  ClientListTableCol,
  HEADER_MENU_BAR,
  ENERGY_UNIT_TYPE,
  IrrigationTableHeader,
  EnergySourceData,
  NotificationCol,
  LandUsageTableHeader,
  DISABLE_CLOSE_MODEL_ID,
  NP_CALCULATOR_API_URL,
  PER_TYPES,
  PER_WEIGHT_UNITS,
  FacilityHeatTable,
  FacilityWaterTable,
  FacilityHeatTableWithoutEdit,
  FacilityWaterTableWithoutEdit,
  COMMON_PROTECT_ROUTE_PATH,
  FACILITY_OWNERSHIP,
  HEAT_SOURCE_UNIT_TYPES,
  REFRIGERANT_UNIT_TYPES,
};
