import { Grid, Table } from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../../components/InputText/InputNumber";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";

const Pesticides = ({
  pesticide,
  pesticideDropdownList,
  onEditHandler,
  control,
  setVisibleDeleteModal,
  setDeleteValue,
  setPesticide,
  onAddClickPesticideHandler,
  pesticideTableList,
  PesticideTableHeader,
  cultivationType,
  pesticideTableSimulateViewList
}: any) => {
  return cultivationType === "Supplier" ? (
    <div>
      <AccordionTitleView
        accordionEnabled={false}
        title={"List of Pesticides"}
        plusIcon={false}
      />
      <div>
        <Grid>
          <Grid.Row divided>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              {pesticideTableSimulateViewList.length > 0 && (
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="pesticideTable"
                >
                  <CommonTable
                    tableHeaderData={PesticideTableHeader.filter(
                      (e: any) => e.name !== ""
                    )}
                    className="pesticideTable"
                    isSimulateView
                  >
                    {pesticideTableSimulateViewList.map((item: any) => {
                      return (
                        <Table.Row key={`pesticide_${item?.id}`}>
                          <Table.Cell>
                            <p>{item?.pesticide_name ?? ""}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{item?.pesticideQuantity ?? ""}</p>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </CommonTable>
                </Grid.Column>
              )}
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid>
                <Grid.Column computer={9} tablet={8} mobile={16}>
                  <DropDown
                    labelName={"Pesticide Name"}
                    placeholder="Select Pesticide Name"
                    defaultValue={pesticide}
                    currentData={pesticideDropdownList}
                    handleChangeState={(e: any, { value }: any) => {
                      setPesticide(value);
                    }}
                    search
                    key={"pesticide_name"}
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={6} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"kg/ha"}
                    placeholder="kg/ha"
                    name="pesticideQuantity"
                  />
                </Grid.Column>
                <Grid.Column computer={2} tablet={2} mobile={16}>
                  <CustomButton
                    theme="green"
                    icon="plus"
                    disabled={!pesticide}
                    customColumnStyle={"plusButton"}
                    onClick={onAddClickPesticideHandler}
                  />
                </Grid.Column>
              </Grid>
              {pesticideTableList.length > 0 && (
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="pesticideTable"
                >
                  <CommonTable
                    tableHeaderData={PesticideTableHeader}
                    className="pesticideTable"
                  >
                    {pesticideTableList.map((item: any) => {
                      return (
                        <Table.Row key={`pesticide_${item?.id}`}>
                          <Table.Cell>
                            <p>{item?.pesticide_name ?? ""}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{item?.pesticideQuantity ?? ""}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <Grid>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <MdModeEditOutline
                                  cursor={"pointer"}
                                  size={24}
                                  color="var(--mainColor)"
                                  onClick={() =>
                                    onEditHandler(item, "Pesticide")
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <MdDeleteOutline
                                  cursor={"pointer"}
                                  size={24}
                                  color="var(--mainColor)"
                                  onClick={() => {
                                    setVisibleDeleteModal(true);
                                    setDeleteValue({
                                      type: "Pesticide",
                                      item,
                                    });
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </CommonTable>
                </Grid.Column>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  ) : null;
};

export default Pesticides;
