import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DrawingContext } from "../SimulateDrawingTool";
import { SearchValue, CustomButton, InputText } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import {
  useGetIngredientWholeList,
  usePutIngredientData,
  usePutSupplierData,
} from "../../../api/ingredients";
import { Dimmer, Loader } from "semantic-ui-react";
import {
  Node,
  getNodesBounds,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { usePutEditProduct } from "../../../api/product";
import { successMessage } from "../../../helpers/ErrorHandler";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { useForm } from "react-hook-form";
import { without } from "lodash";
import { useGetSupplierDrawingToolData } from "../../../api/supplierDrawingTool";
import { nodeCatagories } from "../../../config/drawingConstants";
import { v4 as uuidv4 } from "uuid";
import { concatToNewArray } from "../../../utils/utils";
import { getNodePositionInsideParent } from "../../supplier_drawing_tool/utils";

const initialIngredient = {
  id: null,
  title: "",
  suppliers: [],
  productIds: [],
};

const initialSupplier = {
  id: null,
  title: "",
  supplierData: {
    ingredientIds: [],
    productIds: [],
  },
};

export const IngredientBase = ({ modalData }: any) => {
  const {
    nodeItemId,
    chartEdges,
    chartNodes,
    productId,
    productData,
    setNodeItem,
    setChartNodes,
    setChartEdges,
    saveDrawing,
    saveProductData,
  } = useContext(DrawingContext);
  // get chart node item from node id
  const { deleteElements, getNode } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [ingredient, setIngredient] = useState(initialIngredient);
  const [supplier, setSupplier] = useState(initialSupplier);
  const [visible, setVisible] = useState(false);

  const values = useMemo(() => {
    return {
      ingredientName: ingredient.title,
      supplierName: supplier.title,
    };
  }, [nodeItemId, ingredient, supplier]);

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const nodeItem = useMemo(() => getNode(nodeItemId || ""), [nodeItemId]);

  const {
    data: ingredientListByProduct,
    isLoading: isIngredientListByProductLoading,
    isSuccess,
  } = useGetIngredientWholeList(false);

  const { isLoading: editProductLoading, mutate: editProductData } =
    usePutEditProduct();

  const {
    isLoading: updateIngredientDataLoading,
    mutateAsync: mutatePutIngredient,
  } = usePutIngredientData();

  const { mutateAsync: mutatePutSupplier } = usePutSupplierData();

  const {
    isLoading: isLoadingSupplierDrawing,
    data: supplierDrawing,
    isInitialLoading,
  } = useGetSupplierDrawingToolData(ingredient.id || "", supplier.id || "");

  useEffect(() => {
    if (isSuccess) {
      const ingredient = ingredients?.find(
        (ing: any) => ing?.id === nodeItem?.data?.reference?.ingredientId
      );
      const supplier = ingredient?.suppliers?.find(
        (sup: any) => sup?._id === nodeItem?.data?.reference?.supplierId
      );
      setIngredient(ingredient || initialIngredient);
      setSupplier({
        id: supplier?._id || null,
        title: supplier?.supplier_name || "",
        supplierData: supplier,
      });
    }
  }, [nodeItem, isSuccess]);

  const { height } = useWindowDimensions();
  const disableEdit =
    ingredient.id && nodeItem?.data.reference && supplier.id ? true : false;

  // ingredients array modifying for dropdown
  const ingredients = useMemo(() => {
    return ingredientListByProduct?.map((pi: any) => {
      return {
        id: pi.ingredientData?._id,
        title: pi.ingredientData?.ingredient_name,
        suppliers: pi.ingredientData?.suppliers,
        productIds: pi.ingredientData?.productIds,
      };
    });
  }, [ingredientListByProduct]);

  // supplier array according to selected ingredient
  const suppliers = useMemo(() => {
    return ingredient?.suppliers?.map((pi: any) => ({
      id: pi._id,
      title: pi.supplier_name,
      supplierData: pi,
    }));
  }, [ingredient]);

  const updateProductData = (status: any) => {
    let checkProductIngredientAvailable = productData.product_ingredients.find(
      (e: any) => e === ingredient.id
    );
    if (
      (checkProductIngredientAvailable && !status) ||
      (!checkProductIngredientAvailable && status)
    )
      return;
    const withoutIngredient = without(
      productData.product_ingredients,
      ingredient.id
    );
    let updateProductIngredient = [
      ...productData.product_ingredients,
      ingredient.id,
    ];
    let productDetails = {
      _id: productData?._id,
      product_ingredients: status ? withoutIngredient : updateProductIngredient,
    };
    editProductData(productDetails, {
      onSuccess(data) {
        saveProductData(data);
      },
    });
  };

  const updateIngredient = (status: any) => {
    let checkProductIngredientAvailable = ingredient?.productIds.some(
      (e: any) => e === productId
    );

    if (checkProductIngredientAvailable && !status) return;
    const withoutProduct = without(ingredient.productIds, productId);
    let updateProduct = [...ingredient.productIds, productId];
    const updateIngredientData = {
      _id: ingredient.id,
      ingredient_name: ingredient.title,
      productIds: status ? withoutProduct : updateProduct,
    };
    mutatePutIngredient(updateIngredientData, {
      onSuccess(data: any) {
        successMessage("Update Ingredient");
      },
    });
  };

  const updateSupplierData = (status: any) => {
    let checkProductIngredientAvailable =
      supplier?.supplierData?.ingredientIds?.some(
        (e: any) => e === ingredient.id
      );

    let checkProductAvailable = supplier?.supplierData?.productIds?.some(
      (e: any) => e === productId
    );

    if (checkProductIngredientAvailable && checkProductAvailable && !status)
      return;

    const withoutProduct = without(ingredient.productIds, productId);

    let productIds = status
      ? withoutProduct
      : [...supplier.supplierData.productIds, productId];

    let supplierDataUpdate = {
      _id: supplier.id,
      supplier_name: supplier?.title,
      productIds: productIds,
    };
    mutatePutSupplier(supplierDataUpdate, {
      onSuccess(data: any) {
        successMessage("Supplier update successfully");
      },
    });
  };

  const deleteIngredient = () => {
    setVisible(true);
  };

  const deleteIngredientNode = () => {
    const supplierDrawingId = supplierDrawing?._id;

    const parentData = chartNodes.find(
      (e) => e?.data?.reference?.supplierDrawingId === supplierDrawingId
    );

    const parenId = parentData?.id;

    if (parenId && supplierDrawingId) {
      const currentNode = chartNodes.filter((e: any) => e.id == nodeItem?.id);
      const getNestedChart = chartNodes.filter(
        (e: any) => e.parentNode == parenId
      );
      const checkNestedChartNode = chartNodes.filter(
        (e: any) =>
          getNestedChart.some((test) => e.parentNode == test.id) ||
          e.id == parenId
      );
      const getGroupNodes = [
        ...getNestedChart,
        ...checkNestedChartNode,
        ...currentNode,
      ].map((node) => ({ id: node.id }));
      deleteElements({ nodes: getGroupNodes });
      updateProductData(true);
      updateIngredient(true);
      updateSupplierData(true);
      setNodeItem(null);
    } else {
      updateProductData(true);
      updateIngredient(true);
      updateSupplierData(true);
      deleteElements({ nodes: [{ id: nodeItem?.id || "" }] });
      setNodeItem(null);
    }
  };

  const getIngredientNodes = (parentNodeId: string) => {
    const ingredientNodes: Node[] =
      supplierDrawing?.chartNodes.map((n, index) => {
        const position = {
          x: n.position.x / (index === 0 ? 4 : 2),
          y: n.position.y / 2,
        };
        if (n.parentNode) {
          return { ...n, position };
        }
        return {
          ...n,
          position,
          extent: "parent",
          parentNode: parentNodeId,
        };
      }) || [];
    return ingredientNodes;
  };

  const createIngredientGate = useCallback(
    (ingredientGateId: string) => {
      const newNodeId = ingredientGateId ? ingredientGateId : uuidv4();
      const ingredientNodes: Node[] = getIngredientNodes(newNodeId);
      const rect = getNodesBounds(ingredientNodes, [1, 1]);
      const nodeStyle = { width: rect.width, height: rect.height };
      const position = {
        x: 0,
        y: 0,
      };
      const newNode: Node = {
        id: newNodeId,
        type: nodeCatagories.ingredientGate,
        data: {
          label: "Ingredient Gate",
          icon: "ingredientGate",
          description: "",
          reference: {
            supplierDrawingId: supplierDrawing?._id,
            lastChangeAt: supplierDrawing?.updatedAt,
          },
        },
        position: position,
        style: nodeStyle,
      };
      return { newNode, ingredientNodes, nodeStyle };
    },
    [supplierDrawing]
  );

  const addingSupplierDrawing = useCallback(
    (updatedChartNodes: Node[], updatedNode: any) => {
      const existingIngredientGate = updatedChartNodes.find(
        (node) =>
          node.data.reference?.supplierDrawingId === supplierDrawing?._id
      );
      const { newNode, ingredientNodes, nodeStyle } = createIngredientGate(
        existingIngredientGate?.id || ""
      );
      const nextNodes: Node[] = [
        newNode,
        ...ingredientNodes.map((n) => {
          const position = getNodePositionInsideParent(
            JSON.parse(JSON.stringify(n)),
            { ...nodeStyle, ...newNode }
          );
          return { ...n, position };
        }),
        ...updatedChartNodes,
      ];
      setChartNodes(nextNodes);
      updateNodeInternals(
        concatToNewArray([newNode, ...ingredientNodes], "id")
      );
      setChartEdges([...chartEdges, ...(supplierDrawing?.chartEdges || [])]);
      setNodeItem(updatedNode);
      saveDrawing();
    },
    [
      chartNodes,
      chartEdges,
      setChartNodes,
      setNodeItem,
      saveDrawing,
      createIngredientGate,
    ]
  );

  // submit callback function
  const onSubmit = useCallback(() => {
    updateProductData(false);
    updateIngredient(false);
    updateSupplierData(false);
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        label: ingredient.title,
        // reference is mongoose mix type so you can set any type of here please set necessary reference only
        reference: {
          ingredientId: ingredient.id,
          supplierId: supplier.id,
        },
        description: `${ingredient.title} ${supplier.title}`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });

    if (
      supplierDrawing?._id &&
      !(ingredient.id && nodeItem?.data.reference && supplier.id)
    ) {
      addingSupplierDrawing(updatedChartNodes, updatedNode);
      return;
    }
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing();
  }, [
    nodeItem,
    chartEdges,
    chartNodes,
    ingredient,
    supplier,
    addingSupplierDrawing,
  ]);

  // Loading state check
  if (
    isIngredientListByProductLoading ||
    editProductLoading ||
    updateIngredientDataLoading ||
    (isInitialLoading && isLoadingSupplierDrawing)
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const handleIngredientValidation = (status: any) => {
    if (status) {
      setIngredient(initialIngredient);
      setSupplier(initialSupplier);
    }
  };

  const handleSupplierValidation = (status: any) => {
    if (status) {
      setSupplier(initialSupplier);
    }
  };

  return (
    <>
      <div
        style={{
          height: height * 0.9 - 100,
          overflowX: "hidden",
        }}
      >
        {disableEdit ? (
          <>
            <InputText
              register={register}
              placeholder="Ingredient Name"
              name="ingredientName"
              labelName={"Ingredient Name*"}
              customGridMain="customIngredientNameView"
              disabled={true}
            />
            <InputText
              register={register}
              placeholder="Supplier Name"
              name="supplierName"
              labelName={"Supplier Name*"}
              disabled={true}
            />
          </>
        ) : (
          <>
            <SearchValue
              searchDataValues={ingredients}
              title={"Ingredient Name*"}
              validationMessage="Please Select ingredient"
              checkOther={(status: any) => {
                handleIngredientValidation(status);
              }}
              defaultValue={ingredient.title}
              selectDetails={({ result }: any) => {
                setIngredient(result?.other);
              }}
              disable={disableEdit}
              customMainView="customMainViewIngredientSearch"
            />
            {ingredient.id ? (
              <SearchValue
                searchDataValues={suppliers}
                title={"Supplier Name*"}
                validationMessage="Please Select supplier"
                checkOther={(status: any) => {
                  handleSupplierValidation(status);
                }}
                defaultValue={supplier.title}
                selectDetails={({ result }: any) => {
                  setSupplier(result?.other);
                }}
                disable={disableEdit}
              />
            ) : null}
          </>
        )}
      </div>
      <MainBottomButtonView
        cancelStatus={true}
        saveButtonStatus={true}
        deleteStatus={disableEdit}
        cancelButton={() => setNodeItem(null)}
        saveTitle={
          nodeItem?.data.reference && ingredient.id ? "Update" : "Save"
        }
        saveButton={() => onSubmit()}
        deleteButton={() => deleteIngredient()}
        type="submit"
      />
      <ConfirmModal
        viewModal={visible}
        closeModal={() => setVisible(false)}
        cancel={() => {
          setVisible(false);
        }}
        approve={() => {
          deleteIngredientNode();
        }}
        title="Delete Ingredient"
        subTitle="Are you sure you want to remove the ingredient data?"
      />
    </>
  );
};
