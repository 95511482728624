import React from "react";
import { CustomButton } from "../button/Button";

import './mainBottomButton.scss'
import useWindowDimensions from "../../hooks/windowsSize";

const MainBottomButtonView = ({
  customHeight = null,
  cancelButton = () => {},
  cancelStatus = false,
  deleteStatus = false,
  saveButtonStatus = false,
  deleteButton = () => {},
  saveButton = () => {},
  bounceEnable = false,
  cancel='Cancel',
  saveTitle="Save",
  type="button",
  saveDisable=false,
  isSimulation=false
}: any) => {
  const { height } = useWindowDimensions();

  let heightView = customHeight ? customHeight : height * 0.1;
  return (
    <div
      style={{
        height: heightView,
      }}
      className="mainBottomView"
    >
      <div className="modalBottomRight">
        {cancelStatus && (
          <CustomButton
            theme="green"
            buttonOutLine={true}
            title={cancel}
            onClick={() => cancelButton()}
          />
        )}

        {deleteStatus && (
          <CustomButton
            theme="red"
            icon={"archive"}
            customColumnStyle={"cardInsertSaveOther"}
            onClick={() => {
              deleteButton();
            }}
            buttonOutLine={true}
          />
        )}
      </div>
      <div className="modalBottomLeft">
        {saveButtonStatus && !isSimulation && (
          <CustomButton
            theme="green"
            title={saveTitle}
            onClick={() => saveButton()}
            type={type}
            disabled={saveDisable}
            bounceEnable={bounceEnable}
          />
        )}
      </div>
    </div>
  );
};

export default MainBottomButtonView;
