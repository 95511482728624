import { useCallback, useContext, useMemo, useState } from "react";
import { useReactFlow } from "reactflow";
import { useForm } from "react-hook-form";
import {
  Dimmer,
  Loader,
  Image,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Grid,
} from "semantic-ui-react";

import { DrawingContext } from "../../../SimulateDrawingTool";
import { CustomButton, InputText, TitleView } from "../../../../../components";
import PackagingMethod from "./PackagingMethod";
import Packaging from "./Packaging";
import {
  useDeleteAllPackaging,
  useGetPackagingData,
  useUpdatePackagingDetails,
} from "../../../../../api/farmGate/packaging";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { useGetPackagingTypes } from "../../../../../api/processGate/packaging";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import { images } from "../../../../../assets/images";
import {
  removeNewDrawingItemStatus,
  saveNewDrawingItem,
} from "../../../../../utils/cacheStorage";

export const FarmGatePackagingBase = ({ modalData }: any) => {
  const { packagingId, farmGateId, nodeItem } = modalData;
  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);
  const { height } = useWindowDimensions();
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const [clickedPackagingUnit, setClickedPackagingUnit] = useState("baseUnit");

  const { deleteElements } = useReactFlow();

  //get packaging data by id
  const { data, status, fetchStatus, refetch } =
    useGetPackagingData(packagingId);

  //delete all packaging data
  const { isLoading, mutate } = useDeleteAllPackaging();

  const { isLoading: isLoadingPackageDetails, mutate: mutatePackingDetails } =
    useUpdatePackagingDetails();

  //get packaging data
  const getPackageData = useCallback(
    (param: any) => {
      return data?.[param] || [];
    },
    [data]
  );

  //get packaging types
  const {
    data: packagingTypeData,
    status: packagingTypeLoad,
    fetchStatus: packagingFetch,
  } = useGetPackagingTypes();

  const updatePackagingName = (data: any) => {
    const dataOfPackageDetails = {
      data: {
        packagingName: data.packagingName,
      },
      farmGateId,
      packagingId,
    };
    mutatePackingDetails(dataOfPackageDetails, {
      onSuccess: (data) => {
        if (packagingId) {
          removeNewDrawingItemStatus();
          refetch(packagingId);
        } else {
          saveNewDrawingItem("created");
        }
        savePackingNodeData(data._id, data.packagingName);
      },
    });
  };

  const updatePackingDetails = (value: any, type: string) => {
    // Extracting data from the packageDetails object
    const {
      unitSize,
      numberOfBaseUnits,
      numberOfPacks,
      numberOfCases,
      palletWidth,
      palletLength,
    } = data || {
      unitSize: 0,
      numberOfBaseUnits: 0,
      numberOfPacks: 0,
      numberOfCases: 0,
      palletWidth: 0,
      palletLength: 0,
    };

    // Constructing the packageDetails object
    const packageDetails = {
      farmGateId,
      packagingId,
      packagingType: type,
      unitSize,
      numberOfBaseUnits,
      numberOfPacks,
      numberOfCases,
      palletWidth,
      palletLength,
    };

    // Constructing the data object based on the type
    const dataOfPackageDetails = {
      data: {
        ...packageDetails,
        [type]: type === "numberOfCases" ? value?.packageUnitValue : value,
        palletWidth:
          type === "numberOfCases" ? value?.palletWidth : palletWidth,
        palletLength:
          type === "numberOfCases" ? value?.palletLength : palletLength,
      },
      packagingType: type,
      farmGateId,
      packagingId,
    };

    // Mutating the packing details with the constructed data object
    mutatePackingDetails(dataOfPackageDetails, {
      onSuccess: (data) => {
        if (packagingId) {
          refetch(packagingId);
          return;
        }
        savePackingNodeData(data._id);
      },
    });
  };

  //save transport node data
  const savePackingNodeData = (id: any, packagingName?: string) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        ...(packagingName && { label: `Packaging Details (${packagingName})` }),
        reference: {
          packagingId: id,
        },
        description: `Packaging Details`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing();
  };

  //delete node data
  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const values = useMemo(() => {
    return {
      packagingName: data?.packagingName,
    };
  }, [nodeItem, data, farmGateId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //handle loading
  if (
    (status == "loading" && fetchStatus == "fetching") ||
    (packagingTypeLoad == "loading" && packagingFetch == "fetching") ||
    isLoading ||
    isLoadingPackageDetails
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <div
        style={{
          height: height * 0.9 - 100,
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Accordion>
          <AccordionTitle
            active={activeIndex === 1}
            index={1}
            onClick={() => {
              setActiveIndex(activeIndex === 1 ? 0 : 1);
            }}
          >
            <TitleView title="Please add information about how you package products at each level following the GS1 guidelines. Use this image as a reference." />
          </AccordionTitle>
          <AccordionContent active={activeIndex === 1}>
            <Image src={images.PackingProcess} />
          </AccordionContent>
        </Accordion>
        <TitleView title="Packaging Details" />
        <form onSubmit={handleSubmit(updatePackagingName)}>
          <Grid>
            <Grid.Column computer={12} tablet={12} mobile={12}>
              <InputText
                register={register}
                labelName="Packaging Name"
                name="packagingName"
                errors={errors.packagingName}
                required
                errorMessage="Packaging Name is required"
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={4} mobile={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CustomButton theme="green" title={"Update"} type="submit" />
              </div>
            </Grid.Column>
          </Grid>
        </form>
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="single-unit"
          unitSizes={data?.unitSize}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "unitSize")
          }
          totalWeightOfItem={data?.totalWeightBaseUnit}
          farmGateId={farmGateId}
          packagingData={getPackageData("singleUnitPackaging")}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="sales-unit"
          farmGateId={farmGateId}
          unitSizes={data?.numberOfBaseUnits}
          totalWeightOfItem={data?.totalWeightSalesOfPack}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "numberOfBaseUnits")
          }
          packagingData={getPackageData("salesUnitPackaging")}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="bulk-unit"
          unitSizes={data?.numberOfPacks}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "numberOfPacks")
          }
          totalWeightOfItem={data?.totalWeightBulkPack}
          farmGateId={farmGateId}
          packagingData={getPackageData("bulkUnitPackaging")}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="transport-unit"
          farmGateId={farmGateId}
          unitSizes={data?.numberOfCases}
          palletWidth={data?.palletWidth}
          palletLength={data?.palletLength}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "numberOfCases")
          }
          totalWeightOfItem={data?.totalWeightTransportUnitPack}
          packagingData={getPackageData("transportUnitPackaging")}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <PackagingMethod
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="transport-unit"
          farmGateId={farmGateId}
          packagingData={getPackageData("packagingMethod")}
          savePackingNodeData={savePackingNodeData}
        />
      </div>
      <MainBottomButtonView
        cancelStatus={true}
        deleteStatus={packagingId}
        cancelButton={() => setNodeItem(null)}
        deleteButton={() => setVisibleDeleteModal(true)}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            packagingId,
            farmGateId,
          };
          mutate(dataToDelete, {
            onSuccess(data) {
              onNodesDelete();
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete Farm gate packaging`}
        subTitle="Are you sure you want to remove the Farm gate packaging? Please consider you cannot recover these data after remove"
      />
    </>
  );
};
