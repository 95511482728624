import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  Table,
  TableRow,
} from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../components";
import { FacilityEnergyTable } from "../../../config/constants";
import { MdDeleteOutline } from "react-icons/md";
import { InputNumber } from "../../../components/InputText/InputNumber";

import "./facilityForm.scss";
import { checkYearValidation } from "../../../utils/utils";

const FacilityEnergy = ({
  energyType,
  defaultEnergyType,
  setDefaultEnergyType,
  errorsFacilityEnergy,
  handleSubmitFacilityEnergy,
  addFacilityEnergyData,
  addFacilityEnergy,
  currentFacilityData,
  setDeleteData,
  setVisibleDeleteModal,
  control,
  renderTableData = false,
  setError,
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  const groupFacilityData = (data: any) => {
    let groupIngredientData = data.reduce(
      (currentType: any, data: any, index: any) => {
        data.index = index;
        let checkDataIsAvailable = currentType?.find(
          (other: any) => other.key == data.Year
        );
        if (currentType?.length == 0) {
          let facilityData = {
            key: data.Year,
            value: [data],
          };
          return [...currentType, facilityData];
        } else if (checkDataIsAvailable) {
          let currentIndex = currentType?.findIndex(
            (other: any) => other.key == data.Year
          );
          let updatedArray = [];

          const updateArray = [...currentType[currentIndex].value, data];
          const updatedObject = {
            ...currentType[currentIndex],
            value: updateArray,
          };
          updatedArray = [
            ...currentType.slice(0, currentIndex),
            updatedObject,
            ...currentType.slice(currentIndex + 1),
          ];
          return updatedArray;
        } else {
          let facilityData = {
            key: data.Year,
            value: [data],
          };

          return [...currentType, facilityData];
        }
      },
      []
    );
    return groupIngredientData;
  };
  const groupFacilityByYear = groupFacilityData(currentFacilityData);

  const checkValidData = (currentData: any) => {
    let totalOfPercentage = 0;

    const percentageValueData = groupFacilityByYear.find(
      (data: any) => data.key == currentData.Year
    );

    if (percentageValueData) {
      totalOfPercentage = percentageValueData.value.reduce(
        (currentValue: any, currentData: any) =>
          (currentValue += Number(currentData.Percentage)),
        Number(currentData.Percentage)
      );
    }

    if (totalOfPercentage > 100) {
      setError("Percentage", {
        type: "manual",
        message: `Total of percentage in ${currentData.Year} is over 100`,
      });
      return true;
    }

    return false;
  };

  const renderTableRow = (
    dataView: any,
    rowSpan: any,
    totalValue: any,
    onDelete: any
  ) => (
    <TableRow key={dataView?.id || dataView?.index}>
      {rowSpan > 0 && (
        <Table.Cell rowSpan={rowSpan}>
          <p>{dataView?.Year ?? ""}</p>
        </Table.Cell>
      )}
      <Table.Cell>
        <p>{dataView?.EnergyType ?? 0.0}</p>
      </Table.Cell>
      <Table.Cell>
        <p>{dataView?.kWhConsumed ?? ""} kWh</p>
      </Table.Cell>
      <Table.Cell>
        <p>{dataView?.Percentage ?? 0.0} %</p>
      </Table.Cell>
      <Table.Cell>
        <p>{totalValue}</p>
      </Table.Cell>
      <Table.Cell>
        <Grid>
          {!renderTableData ? (
            <Grid.Column computer={4} tablet={16} mobile={16}>
              <MdDeleteOutline
                cursor="pointer"
                size={24}
                color="var(--mainColor)"
                onClick={onDelete}
              />
            </Grid.Column>
          ) : null}
        </Grid>
      </Table.Cell>
    </TableRow>
  );

  const loadTableData = () => {
    return groupFacilityByYear.map((groupData: any, groupIndex: any) => {
      const rowCount = groupData?.value?.length || 0;
      return groupData?.value?.map((dataView: any, dataIndex: any) => {
        const isFirstRow = dataIndex === 0;
        const kWhValue = dataView?.kWhConsumed || 0;
        const price = dataView?.Price || 0;
        const totalValue = kWhValue * price;

        const handleDelete = () => {
          setDeleteData(dataView);
          setVisibleDeleteModal(true);
        };

        return renderTableRow(
          dataView,
          isFirstRow ? rowCount : 0,
          totalValue,
          handleDelete
        );
      });
    });
  };

  return (
    <>
      <Accordion>
        <AccordionTitle active={accordionEnabled} onClick={handleClick}>
          <AccordionTitleView
            accordionEnabled={accordionEnabled}
            title={"Power source"}
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <Grid>
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className={
                !renderTableData ? "userBankDetailsMain" : "recipeTableViewMain"
              }
            >
              <div>
                {!renderTableData ? (
                  <Grid>
                    <Grid.Column
                      computer={4}
                      tablet={8}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <DropDown
                        labelName={"Source*"}
                        placeholder="Source*"
                        currentData={energyType}
                        defaultValue={
                          energyType?.[defaultEnergyType].value || null
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          let getIndex = energyType?.findIndex(
                            (e: any) => e?.value === value
                          );
                          setDefaultEnergyType(getIndex);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      tablet={8}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <InputNumber
                        control={control}
                        errors={errorsFacilityEnergy.Year}
                        labelName={"Year*"}
                        placeholder="Year"
                        name="Year"
                        required={true}
                        errorMessage="Year is required"
                        otherValidation={{
                          validate: {
                            valueCheck: (v: any) =>
                              checkYearValidation(v) || "Please add valid year",
                          },
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16} />
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <InputNumber
                        control={control}
                        errors={errorsFacilityEnergy.kWhConsumed}
                        labelName={"kWh consumed*"}
                        placeholder="kWh consumed"
                        required={true}
                        name="kWhConsumed"
                        errorMessage="kWh consumed is required"
                        rightContentEnable={true}
                        rightContent={"kWh"}
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <InputNumber
                        control={control}
                        errors={errorsFacilityEnergy.Percentage}
                        labelName={"Percentage distribution*"}
                        placeholder="Percentage distribution"
                        name="Percentage"
                        required={true}
                        errorMessage="Percentage is required"
                        rightContentEnable={true}
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <InputNumber
                        control={control}
                        errors={errorsFacilityEnergy.Price}
                        labelName={"Price pre kWh"}
                        placeholder="Price pre kWh"
                        name="Price"
                      />
                    </Grid.Column>

                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <CustomButton
                        theme="green"
                        customColumnStyle={"addUnitSize"}
                        icon="plus"
                        onClick={handleSubmitFacilityEnergy((data: any) => {
                          if (!checkValidData(data)) {
                            addFacilityEnergyData(data);
                          }
                        })}
                      />
                    </Grid.Column>
                  </Grid>
                ) : null}

                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className={
                    !renderTableData
                      ? "productRegisterTable customTableBottom"
                      : ""
                  }
                >
                  {addFacilityEnergy?.length > 0 && (
                    <CommonTable tableHeaderData={FacilityEnergyTable}>
                      {loadTableData()}
                    </CommonTable>
                  )}
                </Grid.Column>
              </div>
            </Grid.Column>
          </Grid>
        </AccordionContent>
      </Accordion>
    </>
  );
};

export default FacilityEnergy;
