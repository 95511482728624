import { Button, Modal, Segment, Input } from "semantic-ui-react";
import { useState } from "react";

const SimulationDrawingSaveModal = ({
  closeModal = () => {},
  onSave = () => {},
}: any) => {
  const [drawingName, setDrawingName] = useState("");

  return (
    <Modal
      centered={false}
      size={"small"}
      open={true}
      onClose={() => closeModal()}
      dimmer="blurring"
    >
      <Modal.Header>Save drawing diagram!</Modal.Header>
      <Modal.Content as={Segment}>
        <Input
          label="Drawing Save Name:"
          value={drawingName}
          onChange={(e, { value }) => {
            setDrawingName(value);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          positive
          onClick={() => {
            onSave(drawingName);
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SimulationDrawingSaveModal;
