import React, { useEffect } from "react";
import { Grid, Table } from "semantic-ui-react";

import {
  CommonTable,
  SearchValue,
  CommonModal,
  CustomButton,
  InputText,
} from "../../../components";
import {
  Energy,
  EnergyTable,
  Packaging,
  Processing,
  ProcessRecipe,
  Storage,
  Transportation,
} from "../../../config/constants";

import "./editProcess.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const EditProcessModal = ({ visibleModal, setVisibleModal }: any) => {
  const navigate = useNavigate();

  let { processData } = useSelector((state: RootState) => state.process);

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("productName", processData?.productName || "");
  }, [processData]);

  const loadProductRecipeData = () => {
    return processData?.receipe.map((data: any, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.ingredientName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.content}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.prcentage}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadProcessingData = () => {
    return processData?.processing.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.processingName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.processingType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.input}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.output}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.capacity}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadPackagingData = () => {
    return processData?.packaging.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.packageName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.meaterialWeight}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.packageMaterial}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadStorageData = () => {
    return processData?.storage.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.warehouseName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.warehouseSize}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.temperature}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.electricity}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadEnergyData = () => {
    return processData?.energyGrid.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.energyType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.kWh}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.percentage}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTransportData = () => {
    return processData?.internalTransport.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.transportType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.averageDistance}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => setVisibleModal(false)}
        centered={true}
        
        title="Processing Impact"
      >
        <Grid>
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <InputText register={register} name="productName" disabled={true} />
          </Grid.Column>
        </Grid>
        <>
          {processData?.receipe ? (
            <>
              <p className="confirmationTitle">Product Recipe</p>
              <Grid>
                <Grid.Column computer={9} tablet={8} mobile={16}>
                  <CommonTable tableHeaderData={ProcessRecipe}>
                    {loadProductRecipeData()}
                  </CommonTable>
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                  <CustomButton
                    title="Edit"
                    onClick={() => {
                      navigate(`recipe/${processData?._id}`);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </>
          ) : null}

          {processData?.processing ? (
            <>
              <p className="confirmationTitle">Process</p>

              <Grid>
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="processDetailsTableView"
                >
                  <CommonTable tableHeaderData={Processing}>
                    {loadProcessingData()}
                  </CommonTable>
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                  <CustomButton
                    title="Edit"
                    onClick={() => {
                      navigate(`processing/${processData?._id}`, {
                        state: {
                          edit: true
                        }
                      });
                    }}
                  />
                </Grid.Column>
              </Grid>
            </>
          ) : null}

          {processData?.packaging ? (
            <>
              <p className="confirmationTitle">Packing</p>

              <Grid>
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="processDetailsTableView"
                >
                  <CommonTable tableHeaderData={Packaging}>
                    {loadPackagingData()}
                  </CommonTable>
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                  <CustomButton
                    title="Edit"
                    onClick={() => {
                      navigate(`packing/${processData?._id}`);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </>
          ) : null}

          {processData?.storage ? (
            <>
              <p className="confirmationTitle">Storage</p>

              <Grid>
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="processDetailsTableView"
                >
                  <CommonTable tableHeaderData={Storage}>
                    {loadStorageData()}
                  </CommonTable>
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                  <CustomButton
                    title="Edit"
                    onClick={() => {
                      navigate(`storage/${processData?._id}`);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </>
          ) : null}

          {processData?.energyGrid ? (
            <>
              <p className="confirmationTitle">Energy</p>

              <Grid>
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="processDetailsTableView"
                >
                  <CommonTable tableHeaderData={Energy}>
                    {loadEnergyData()}
                  </CommonTable>
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                  <CustomButton
                    title="Edit"
                    onClick={() => {
                      navigate(`energy/${processData?._id}`);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </>
          ) : null}

          {processData?.internalTransport ? (
            <>
              <p className="confirmationTitle">Transportation</p>

              <Grid>
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="processDetailsTableView"
                >
                  <CommonTable tableHeaderData={Transportation}>
                    {loadTransportData()}
                  </CommonTable>
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                  <CustomButton
                    title="Edit"
                    onClick={() => {
                      navigate(`transportation/${processData?._id}`);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </>
          ) : null}
        </>
      </CommonModal>
    </>
  );
};

export default EditProcessModal;
