import { Grid } from "semantic-ui-react";
import { AccordionTitleView, DropDown } from "../../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../../components/InputText/InputNumber";

const Fields = ({
  cultivationType,
  control,
  checkDateValidation,
  errors,
  errorStatus,
  errorMessage,
  HarvestSeasonData,
  harvestSeason,
  setHarvestSeason,
  formData,
}: any) => {
  return (
    <div>
      <AccordionTitleView
        accordionEnabled={false}
        title={"Field"}
        plusIcon={false}
      />
      <div>
        <Grid>
          <Grid.Row divided>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid columns={2}>
                <Grid.Row>
                  {formData?.dataType === "Supplier" ? (
                    <Grid.Column style={{ marginBottom: 15 }}>
                      <label className="textLabel">Yield (kg/ha)</label>
                      <br />
                      <label className="textLabel">{formData?.yield}</label>
                    </Grid.Column>
                  ) : null}
                  <Grid.Column>
                    <label className="textLabel">Harvest year</label>
                    <br />
                    <label className="textLabel">{formData?.harvestYear}</label>
                  </Grid.Column>
                  {formData?.dataType === "Supplier" ? (
                    <Grid.Column>
                      <label className="textLabel">Growth Area (ha)</label>
                      <br />
                      <label className="textLabel">
                        {formData?.growthArea}
                      </label>
                    </Grid.Column>
                  ) : null}

                  <Grid.Column>
                    <label className="textLabel">Harvest season</label>
                    <br />
                    <label className="textLabel">
                      {formData?.harvestSeason}
                    </label>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Grid columns={2}>
                <Grid.Row>
                  {cultivationType === "Supplier" ? (
                    <Grid.Column>
                      <InputNumber
                        transform={{
                          input: (value: any) =>
                            isNaN(value) || value === 0 ? "" : value.toString(),
                          output: (e: any) => {
                            return e;
                          },
                        }}
                        control={control}
                        labelName={"Yield (kg/ha)"}
                        placeholder="Yield (kg/ha)"
                        name="yield"
                      />
                    </Grid.Column>
                  ) : null}
                  <Grid.Column>
                    <InputNumber
                      labelName={"Harvest year"}
                      transform={{
                        input: (value: any) =>
                          isNaN(value) || value === 0 ? "" : value.toString(),
                        output: (e: any) => {
                          return checkDateValidation(e.target.value);
                        },
                      }}
                      control={control}
                      errors={errors.harvestYear || errorStatus}
                      errorMessage={errorMessage}
                      name="harvestYear"
                    />
                  </Grid.Column>
                  {cultivationType === "Supplier" ? (
                    <Grid.Column>
                      <InputNumber
                        transform={{
                          input: (value: any) =>
                            isNaN(value) || value === 0 ? "" : value.toString(),
                          output: (e: any) => {
                            return e;
                          },
                        }}
                        control={control}
                        labelName={"Growth Area (ha)"}
                        placeholder="Growth Area (ha)"
                        name="growthArea"
                      />
                    </Grid.Column>
                  ) : null}

                  <Grid.Column>
                    <DropDown
                      labelName={"Harvest season"}
                      placeholder="Select Season"
                      currentData={HarvestSeasonData}
                      defaultValue={harvestSeason}
                      handleChangeState={(e: any, { value }: any) => {
                        setHarvestSeason(value);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Fields;
