import React, { useState, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { useForm, useFieldArray } from "react-hook-form";

import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonModal,
  TitleView,
  ItemAdder,
  DropDown
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { OriginCountries } from "../../../config/originCountryData";
import { useGetProductSalesUnits } from "../../../api/product";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";
import { addClient } from "../../../store/actions/client";
import {
  ProductTitle
} from "../../../config/constants";
import "./ClientRegister.scss";
import { useAppDispatch } from "../../../store/hooks";

const ClientRegisterModal = ({ visibleModal, setVisibleModal }: any) => {
  const dispatch = useAppDispatch();
  let defaultProductDetails = {
    productName: '',
    productId: 0,
    salesUnitName: '',
    salesUnitId: 0
  };

  const values = useMemo(() => {
    return {
      productList: [
        defaultProductDetails,
      ],
      clientName: "",
      email: "",
      address: "",
      contactNumber: "",
      city: "",
      province: "",
      country: "",
      otherInfo: "",
      zipCode: ""
    };
  }, []);

  const {
    register,
    setValue,
    control,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
  });

  const {
    fields: productFields,
    append: productsAppend,
    remove: productsRemove,
    update,
  } = useFieldArray({ control, name: "productList" });

  const { height } = useWindowDimensions();
  const [searchKey, setSearchKey] = useState("");

  const { data: productSalesUnitsData, isLoading: isProductSalesUnitsLoading } =
    useGetProductSalesUnits({ searchKey });

  const onSubmit = (data: any) => {
    const newValues = getValues()
    const newProducts = newValues?.productList?.map((prodct) => {
      return {
        _id: prodct.productId,
        productName: prodct.productName,
        salesUnitId: prodct.salesUnitId,
        salesUnitName: prodct.salesUnitName,
      }
    })
    const dataToSubmit = {
      clientAddress: newValues?.address,
      clientCity: newValues?.city,
      clientCountry: newValues?.country,
      clientEmail: newValues?.email,
      clientName: newValues?.clientName,
      clientOtherInfo: newValues?.otherInfo,
      clientPhone: newValues?.contactNumber,
      clientProvince: newValues?.province,
      clientZipCode: newValues?.zipCode,
      product: newProducts,
    }
    dispatch(addClient(dataToSubmit)).then(res => {
      setVisibleModal(false)
      reset()
    })
  };

  const [selectCountry, setSelectCountry] = useState<any>("");

  const addProduct = (index: any) => {
    let productName =
      getValues().productList?.[index]?.productName || null;
    if (!productName) {
      setError(`productList.${index}.productName`, {});
    } else {
      productsAppend(defaultProductDetails);
    }
  };

  const productSalesUnits: DropdownOption[] = (productSalesUnitsData || []).map(
    ({ productName, salesUnitName, salesUnitId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
    })
  );

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      title="Add New Client"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflowX: "hidden",
                }}
              >
                <Grid>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.clientName}
                      labelName={"Client Name*"}
                      placeholder="Client Name"
                      name="clientName"
                      required
                      errorMessage={'Client Name is required'}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.email}
                      labelName={"Email"}
                      placeholder="Email"
                      name="email"
                      pattern={'[a-z0-9]+@[a-z]+\.[a-z]{2,3}'}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.contactNumber}
                      labelName={"Contact Number"}
                      placeholder="Contact Number"
                      name="contactNumber"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.address}
                      labelName={"Address"}
                      placeholder="Address"
                      name="address"
                    />
                  </Grid.Column>

                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.city}
                      labelName={"City"}
                      placeholder="City"
                      name="city"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.province}
                      labelName={"Province"}
                      placeholder="Province"
                      name="province"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <DropDown
                    labelName={"Country"}
                    placeholder="Country"
                    currentData={OriginCountries}
                    defaultValue={selectCountry}
                    handleChangeState={(e: any, { value }: any) => {
                      setValue("country", value);
                      setSelectCountry(value);
                    }}
                    customGridColumn={"customGridColomnFilter"}
                  />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      errors={errors.zipCode}
                      labelName={"Zip Code"}
                      placeholder="Zip Code"
                      name="zipCode"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputTextArea
                      register={register}
                      errors={errors.otherInfo}
                      labelName={"Other Information"}
                      placeholder="Other Information"
                      name="otherInfo"
                      customInputValidation={true}
                    />
                  </Grid.Column>
                </Grid>

                <TitleView
                  title="Add Product"
                  CustomTitleViewMain="supplierAddCustomIngredientTitle"
                />
                <ItemAdderTitleView titleData={ProductTitle} />
                {productFields.map((item: any, index) => {
                  let salesUnitId = item.salesUnitId;
                  return (
                    <ItemAdder
                    itemAddHandle={() => addProduct(index)}
                    itemRemoveHandle={productsRemove}
                      items={productFields}
                      currentIndex={index}
                      computerMainView={10}
                      tabletMainView={16}
                      mobileMainView={16}
                      customItemAdderGridMain="supplierCustomItemAdderGridMain"
                      customItemAdderGridWithInputs="supplierCustomItemAdderGridWithInputs paddingRemoveVertical paddingRemoveLeft "
                      customItemAddButtonMain="supplierCustomItemAddButtonMain"
                    >
                      <DropDown
                        placeholder="Select Product"
                        currentData={productSalesUnits}
                        defaultValue={salesUnitId}
                        handleChangeState={(e: any, { value }: any) => {
                          // getProductValue(value)
                          // setSalesUnitId(value as string);
                        const productDataSet = productSalesUnitsData?.find(
                          (productSalesUnit) => productSalesUnit.salesUnitId === value
                        );
                        const productNamee = productDataSet?.productName
                        const productIdd = productDataSet?.productId
                        // const salesUnitIdd = productDataSet?.salesUnitId
                        const salesUnitName = productDataSet?.salesUnitName
                        // setProductId(productIdd);
                        // setSalesUnitId(salesUnitIdd)
                        // setSalesUnitName(salesUnitName)
                          clearErrors(
                            `productList.${index}.productName`
                          );
                          let tempData = item;
                          delete tempData.id
                          update(index, {
                            ...tempData,
                            productName: productNamee,
                            productId: productIdd,
                            salesUnitName: salesUnitName,
                            salesUnitId: value
                          });
                        }}
                        onSearchChange={(e, data) => {
                          setSearchKey(data.searchQuery);
                        }}
                        loading={isProductSalesUnitsLoading}
                        search
                      />
                    </ItemAdder>
                  );
                })}
              </div>

              <div
                style={{
                  height: height * 0.1,
                }}
                className="modalRegisterButtonMain"
              >
                <div className="modalRegisterButtonLeft">
                  <CustomButton
                    theme="green"
                    title={"Save Client"}
                    customColumnStyle={"cardInsertSaveOther"}
                    type={"submit"}
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default ClientRegisterModal;
