import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { CommonModal } from "../../../components";

import useWindowDimensions from "../../../hooks/windowsSize";
import { useGetIngredientPackaging } from "../../../api/ingredients";
import IngredientDataRegister from "../ingredientRegister/IngredientDataRegister";
import SupplerRegister from "../ingredientRegister/SupplierRegister";
import IngredientPackagingView from "../ingredientRegister/IngredientPackageing";

const IngredientDetailsModal = ({
  ingredientIdToUpdate,
  visibleModal,
  setVisibleModal,
}: any) => {
  const { height } = useWindowDimensions();

  const [viewPackage, setViewPackage] = useState<any>({
    processIngredient: false,
    produceIngredient: false,
    enableIngredient:false
  });

  let ingredientId = ingredientIdToUpdate?.ingredient_id || null;
  let supplierId = ingredientIdToUpdate?.supplier_id || null;  

  const packageDataView =
    viewPackage.enableIngredient
      ? true
      : false;

  let getPackagingData = {
    ingredientId: ingredientId,
    supplierId: supplierId,
    loadPackageData: visibleModal,
  };

  const {
    data: ingredientPackageData,
    isLoading: loadIngredientPackageData,
    refetch: ingredientPackageDataRefetch,
  } = useGetIngredientPackaging(getPackagingData);

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      
      title="Edit Product Ingredient"
    >
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <div
            style={{
              height: height * 0.9 - 50,
              overflowX: "hidden",
            }}
          >
            <IngredientDataRegister
              ingredientListByProduct={[]}
              currentIngredientData={ingredientIdToUpdate}
              disableEdit={true}
            />
            <SupplerRegister
              ingredientSupplierList={[]}
              ingredientData={ingredientIdToUpdate}
              supplierCurrentData={() => {}}
              ingredientList={[]}
              disableEdit={true}
              dataView={true}
              changeDataIngredient={setViewPackage}
            />
            {packageDataView ? (
              <IngredientPackagingView
                ingredientId={ingredientId}
                supplierId={supplierId}
                refetchPackagingData={ingredientPackageDataRefetch}
                ingredientPackageData={ingredientPackageData}
                dataView={true}
              />
            ) : null}
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default IngredientDetailsModal;
